import React, {Component} from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route 
} from "react-router-dom";
import './App.module.css';
import './App.css';
import About from './containers/About/About'
import Focus from './containers/Focus/Focus'
import Products from './containers/Products/Products'
import Contact from './containers/Contact/Contact' 
import Policy from './containers/Contact/Policy' 

import Admin from './Admin/Admin'
import AdminUsersInsert from './Admin/AdminUsers/AdminUsersInsert'
import AdminUsersUpdate from './Admin/AdminUsers/AdminUsersUpdate'

import NewsInsert from './Admin/AdminNews/NewsInsert'
import NewsUpdate from './Admin/AdminNews/NewsUpdate';

import ProductsInsert from './Admin/AdminProducts/ProductsInsert'
import ProductsUpdate from './Admin/AdminProducts/ProductsUpdate';

import PeopleInsert from './Admin/AdminPeople/PeopleInsert'
import PeopleUpdate from './Admin/AdminPeople/PeopleUpdate';

import semper from './assets/images/semperMain.png'
import lines from './assets/images/lines.svg'
import linesBlue from './assets/images/linesBlue.svg'
import semperFooter from './assets/images/semperFooter.svg'
import classes from './App.module.css';

import Header from './containers/HomePage/Header'
import Partners from './containers/HomePage/Partners'
import OurProducts from './containers/HomePage/Products'
import OurNews from './containers/HomePage/News'
import Footer from './containers/HomePage/Footer'
import Side from  './containers/SideBar/SideBar'
import Login from './Admin/Login';
 

class App extends Component  {
   state={
      width: window.innerWidth,    
      scrolled:true,
      outside:false,
      transform:'',
      scrolledDown:false,
      scrolledMobile:false,
      sideBar:false,
      language:'eng', 
      location:localStorage.getItem('location')
    }   
    componentDidMount() {  
      
      window.addEventListener('resize', this.handleWindowSizeChange)
      window.addEventListener('scroll', this.listenToScroll)
    }
    componentWillUnmount() {
      window.removeEventListener('resize', this.handleWindowSizeChange);
      window.removeEventListener('scroll', this.listenToScroll)
    }
    handleWindowSizeChange = () => {
      this.setState({ width: window.innerWidth});
    };
    listenToScroll = () => {
      const winScroll =
        document.body.scrollTop || document.documentElement.scrollTop
    
      const height =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight
    
      const scrolled = winScroll / height
      
      if(scrolled<0.167704280155642) {
        if(this.state.scrolled===false){
          this.setState({scrolled:true})
        }
      }else if(this.state.scrolled===true){
        this.setState({scrolled:false})
      }
      if(scrolled>0.37255935870248597) {
        if(this.state.scrolledDown===false){
          this.setState({scrolledDown:true})
        }
      }else if(this.state.scrolledDown===true){
        this.setState({scrolledDown:false})
      } 
     
    }
    
    HandleLanguage= (event) =>{
      this.setState({language:event.target.value})
      localStorage.setItem('language', event.target.value); 
    }
    openHandler=()=>{
      this.setState({sideBar:!this.state.sideBar})
      this.setState({outside:false})
      if(this.state.transform==='translateX(-34%)' || this.state.transform==='translateX(-30%)'){
        this.setState({transform:''})
      } else{
        this.setState({transform:'translateX(-34%)'})
      }
    }
    outsideHandle=()=>{
      if(this.state.sideBar){
        this.setState({outside:true})
        this.setState({transform:''})
        this.setState({sideBar:false})
      }  
    }
    HandleLink(e){  
      window.location.href = `/`+e.target.id
      localStorage.setItem('location', '/'+e.target.id); 
     
    }
   
  render() {

    if(!localStorage.getItem('language')){ 
      localStorage.setItem('language', 'eng'); 
      this.setState({language:'eng'})
    }
    const { width } = this.state; 
    const isTablet = width <= 768;
    const isMobile = width <= 415; 
    let header, sidebar  
    
    if(window.location.pathname==='/' &&  localStorage.getItem('location')!=='/'){ 
      localStorage.setItem('location', '/'); 
    } 
    if(this.state.sideBar){
      sidebar=(<Side HandleLanguage={this.HandleLanguage}/>)
    }else{
      sidebar=(<div></div>)
    }
    if(window.location.href.indexOf('/admin')>-1){
      header=(<div></div>)
    }else if(localStorage.getItem('location')!=='/' && isTablet){
      header=
        (
        <div className={classes.Div} style={{zIndex: '1005',backgroundImage: "linear-gradient(to bottom, #2e3b5b, #242e47 71%, #0f1836)" }}>
          <img alt="img" id="" onClick={this.HandleLink} className={classes.logo} style={{width:'15%'}}  src={semper}/>
          <img alt="img" className={classes.sidebarLogo} onClick={this.openHandler}  src={lines }/>
        </div>
      )
    }else if(localStorage.getItem('location')!=='/' ){
      header=
        (
        <div className={classes.Div} style={{zIndex: '1005',backgroundImage: "linear-gradient(to bottom, #2e3b5b, #242e47 71%, #0f1836)" }}>
          <img alt="img" id="" onClick={this.HandleLink} className={classes.logo} src={semper}/>
          <ul>
            <button id="about" className={ classes.linkText} onClick={this.HandleLink} >{localStorage.getItem('language')==='eng'? 'About Us': 'Hakkımızda'}</button>
            <button id="focus" className={ classes.linkText} onClick={this.HandleLink}>{localStorage.getItem('language')==='eng'? ' Our Focus':'Odaklarımız'}</button>
            <button id="products" className={ classes.linkText}onClick={this.HandleLink}>{localStorage.getItem('language')==='eng'? ' Products':'Ürünlerimiz'}</button>
            <button id="contact" className={ classes.linkText} onClick={this.HandleLink}>{localStorage.getItem('language')==='eng'? ' Contact':'İletişim'}</button>
            <select value={localStorage.getItem('language')} className={classes.select} onChange={this.HandleLanguage} style={{color:'#ffffff'} } name="lang" id="lang">
              <option value="eng">ENG </option>
              <option value="tr">TR </option> 
            </select>
          </ul>
        </div>
      )
    }
    else if(isTablet){
      header=(
      <div className={classes.Div} style={{zIndex:this.state.scrolled|| this.state.scrolledDown? '1002' :'auto', backgroundColor:this.state.scrolledDown? '#ffffff':'inherit'}}>
        <img alt="img" id="" onClick={this.HandleLink} className={classes.logo} style={{width:'15%'}} src={this.state.scrolledDown? semperFooter:semper}/>
        <img alt="img" className={classes.sidebarLogo} onClick={this.openHandler}  src={this.state.scrolled? lines:linesBlue}/>
      </div>)
    }else if(isMobile){
      header=(
        <div className={classes.Div} style={{ backgroundColor:this.state.scrolled? 'inherit':'#ffffff'}}>
          <img alt="img" id="" onClick={this.HandleLink} className={classes.logo} style={{width:'15%'}} src={this.state.scrolled? semper: semperFooter}/>
          <img alt="img" className={classes.sidebarLogo}  onClick={this.openHandler}  src={this.state.scrolled? lines:linesBlue}/>
        </div>)
    }
    else{
      header=
        (
        <div className={classes.Div} style={{zIndex:this.state.scrolled|| this.state.scrolledDown? '1002' :'auto',backgroundImage:localStorage.getItem('location')!=='/'?"linear-gradient(to bottom, #2e3b5b, #242e47 71%, #0f1836)":"", backgroundColor:  this.state.scrolledDown? '#ffffff':'inherit'}}>
          <img alt="img" id="" onClick={this.HandleLink} className={classes.logo} src={this.state.scrolledDown? semperFooter:semper}/>
          <ul>
            <button id="about" className={this.state.scrolledDown? classes.linkText2 : classes.linkText} onClick={this.HandleLink} >{localStorage.getItem('language')==='eng'? 'About Us': 'Hakkımızda'}</button>
            <button id="focus" className={this.state.scrolledDown? classes.linkText2 : classes.linkText} onClick={this.HandleLink}>{localStorage.getItem('language')==='eng'? ' Our Focus':'Odaklarımız'}</button>
            <button id="products" className={this.state.scrolledDown? classes.linkText2 : classes.linkText} onClick={this.HandleLink}>{localStorage.getItem('language')==='eng'? ' Products':'Ürünlerimiz'}</button>
            <button id="contact" className={this.state.scrolledDown? classes.linkText2 : classes.linkText} onClick={this.HandleLink}>{localStorage.getItem('language')==='eng'? ' Contact':'İletişim'}</button>
            <select value={localStorage.getItem('language')} className={classes.select} onChange={this.HandleLanguage} style={{color:this.state.scrolledDown?'#0081e9':'#ffffff'} } name="lang" id="lang">
              <option value="eng">ENG </option>
              <option value="tr">TR </option> 
            </select>
          </ul>
        </div>
      )
    }
   
    
    var exactWidth = 1524
    document.body.style.zoom = document.documentElement.offsetWidth/exactWidth
    window.onresize = ()=>{
        document.body.style.zoom = document.documentElement.offsetWidth/exactWidth
    } 
    
    return (
      <div >
        <Router>
          <div className={classes.header}> 
          {header}
            <div onClick={this.outsideHandle} className={classes.AppAll}  style={{transform: this.state.transform,display:window.location.pathname!=='/'? 'none':'block' }}> 
              <Header isTablet={isTablet}/>
              <Partners isTablet={isTablet} isMobile={isMobile}/>
              <OurProducts/>
              <OurNews isMobile={isMobile}/>
              <Footer isTablet={isTablet} isMobile={isMobile}/>
            </div>
            {sidebar} 

            <Switch>
              <Route exact path="/admin" component={Login} />
              <Route exact path="/about" render={(props) => (
                <About outsideHandle={this.outsideHandle} sideBar={this.state.sideBar} isTablet={isTablet} isMobile={isMobile} />) }/>  
              <Route exact path="/focus" render={(props) => (
                <Focus outsideHandle={this.outsideHandle} sideBar={this.state.sideBar} isTablet={isTablet} isMobile={isMobile} />) }/>  
              <Route exact path="/products"  render={(props) => (
                <Products outsideHandle={this.outsideHandle} sideBar={this.state.sideBar} isTablet={isTablet} isMobile={isMobile} />) }/> 
              <Route exact path="/contact" render={(props) => (
                <Contact outsideHandle={this.outsideHandle} sideBar={this.state.sideBar} isTablet={isTablet} isMobile={isMobile} />) }/> 
	      <Route exact path="/policy" render={(props) => (
                <Policy outsideHandle={this.outsideHandle} sideBar={this.state.sideBar} isTablet={isTablet} isMobile={isMobile} />) }/> 



              <Route path="/admin/adminUsers" exact render={(props) => (
                <Admin route={"user"} isAuthed={true} />) }/>
              <Route path="/admin/products"  exact render={(props) => (
                <Admin route={"product"} isAuthed={true} />) } />
              <Route path="/admin/people" exact render={(props) => (
                <Admin route={"people"} isAuthed={true} />) } />
              <Route path="/admin/news" exact render={(props) => (
                <Admin route={"news"} isAuthed={true} />) }/>
              <Route path="/admin/clients" exact render={(props) => (
                <Admin route={"clients"} isAuthed={true} />) }/> 
              <Route path="/admin/adminUsers/create" exact component={AdminUsersInsert} />
              <Route
                  path="/admin/adminUsers/update/:id"
                  exact
                  component={AdminUsersUpdate}
              />
              <Route path="/admin/news/create" exact component={NewsInsert} />
              <Route 
                  path="/admin/news/update/:id"
                  exact
                  component={NewsUpdate}
              />
              <Route path="/admin/products/create" exact component={ProductsInsert} />
              <Route
                  path="/admin/products/update/:id"
                  exact
                  component={ProductsUpdate}
              />
              <Route path="/admin/people/create" exact component={PeopleInsert} />
              <Route
                  path="/admin/people/update/:id"
                  exact
                  component={PeopleUpdate}
              />
      
            </Switch>
          </div>
        </Router>
      </div>
    );
  }
}


export default App;
