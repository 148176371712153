import React, {Component} from 'react';
import apis from '../../api/index'
import ClientsInsert from './ClientsInsert';
import classes from './AdminClients.module.css'
import newClient from '../../assets/images/newClient.png'
import ClientsUpdate from './ClientsUpdate'
import logoHolder from '../../assets/images/logoHolder.png'
import DeleteClients from './DeleteClients'


class Clients extends Component  {
  constructor(props) {
    super(props);
    this.state={
    clients:[],
    isLoading: false,
    create:false,
    update:'',
    dragged:''
    }
    this.createClients = this.createClients.bind(this)
  }
  componentDidMount(){
     apis.getAllClients().then(a => {
            this.setState({
              clients: a.data.data,
            })
        })
        localStorage.setItem("page","clients")
    }
createClients(){
    var temp=this.state.create
    this.setState({create:!temp})
  }
  onDragStart = (event) => {
    this.setState({dragged:event.target.dataset.position})
 }
 onDragOver = (event) => {
   if(event.target.dataset.position!==undefined){
     this.setState({drag:true})
     this.setState({dragedOver:event.target.dataset.position})
   }else{
     this.setState({dragedOver:null})
     this.setState({drag:false})
   }
   event.preventDefault();
 }
 onDrop= async  (event) => {
   event.preventDefault();
 
   if(this.state.drag){ 
     let e=event.target.dataset.position
     let dragged=this.state.clients[this.state.dragged]
     let draggedOver=this.state.clients[e] 
     var id=draggedOver._id
     var {name, logoBW ,link} = dragged
     var payload = {id,name ,logoBW ,link}

     await apis.updateClientsById(id, payload).then(res => {

     })
      id=dragged._id
      name= draggedOver.name 
      logoBW= draggedOver.logoBW
      link = draggedOver.link
     
     payload = {id,name, logoBW ,link}
     await apis.updateClientsById(id, payload).then(res => {

   })
       this.setState(prevState => {
         let clients = [...prevState.clients];
         let index=this.state.dragged
         let temp = clients[e];
         clients[e ] = clients[index];
         clients[index] = temp;
       return { clients }
     })
     this.setState({dragedOver:null})
     this.setState({drag:false})
     this.componentDidMount()
   
   }
   
 }
  render() {  
    let createProductDiv=(<button className={classes.createNew} onClick={this.createClients}><img alt="img" className={classes.createImg} src={newClient}/><p className={classes.createTitle}>CREATE NEW CLIENTS </p></button>)
    if(this.state.create){
      createProductDiv=(<ClientsInsert/>)
    }
    
    return (
      <div>
        <p className={classes.Title}>Clients</p>
        {createProductDiv}
        <div className={classes.table}>
            {this.state.clients? this.state.clients.map((user, index) => {
            const { _id,name,  logoBW} = user 
              return (
                <div  draggable="true" data-position={index} onDragStart={this.onDragStart} onDragOver={this.onDragOver} onDrop={this.onDrop} className={classes.projectDiv}  style={{ height:this.state.update===_id?'309px':'100px', opacity: this.state.dragedOver===index?'0.3':'1'}} key={_id}>
                  <img  alt="img" className={classes.adminImg} style={{ display:this.state.update===_id?'none':'inline'}} src={logoBW!==''?`../..${logoBW}` :logoHolder}/>
                  <p className={classes.text} style={{ display:this.state.update===_id?'none':'inline'}}>{name}</p>
                  <DeleteClients id={_id} display={this.state.update===_id}/>
                  <button className={classes.update}  style={{ display:this.state.update===_id?'none':'inline'}} onClick={()=>this.setState({update:_id})}>UPDATE</button>
                  {this.state.update===_id? <ClientsUpdate id={_id} />:<div></div>}
                </div>
              )
            }): <div>Nothing to show!</div>}  
          </div>
        
      </div>
     
    );
    }
}

export default Clients;