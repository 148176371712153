import React, {Component} from 'react';
import Footer from '../HomePage/Footer'
import classes from './Contact.module.css'
import { Email} from 'react-html-email';
import { renderEmail } from 'react-html-email'
import axios from 'axios';
class Contact extends Component  {
  constructor(props) {
    super(props);
      this.state={ 
        name:'',
        email:'',
        subject:'',
        message:''

  }   
  this.emailContentHandle = this.emailContentHandle.bind(this);
  this.messageContentHandle = this.messageContentHandle.bind(this);
  this.nameContentHandle = this.nameContentHandle.bind(this);
  this.subjectContentHandle = this.subjectContentHandle.bind(this);
}
  componentDidMount() { 
    if( localStorage.getItem('location')!=='/contact'){
      localStorage.setItem('location', '/contact'); 
    }
  }
  messageContentHandle(event){
    this.setState({message:event.target.value})
  }
  emailContentHandle(event){
    this.setState({email:event.target.value})
  }
  nameContentHandle(event){
    this.setState({name:event.target.value})
  }
  subjectContentHandle(event){
    this.setState({subject:event.target.value})
  }
  HandleMail=()=>{

    const messageHtml = renderEmail( 
    <Email>
      <h1>
        Subject: {this.state.subject}
      </h1>
      <h3>From: {this.state.name} --  {this.state.email}</h3>
        <p>
        {this.state.message}
          </p> 
    </Email>
  )
 
    
    axios({
      method: "POST", 
      url:"http://web.sempertech.com.tr/contact", 
      data: {
        subject:this.state.subject,
        name: this.state.name,
        email:  this.state.email,
        messageHtml:messageHtml
        }
    }).then((response)=>{
        if (response.data.msg === 'success'){
            alert("Emailiniz bize ulaştı, en kısa sürede dönüş yapılacaktır!"); 
        }else if(response.data.msg === 'fail'){
            alert("Oops, bir şeyler yanlış gitti. Tekrar deneyin lütfen")
        }
    })
  }

  render() {
    return (
      <div className={classes.mainDiv} style={{transform: this.props.sideBar?'translateX(-34%)':'' }} onClick={this.props.outsideHandle}>
        <div className={classes.head}></div>
        <div className={classes.titleDiv}>
          <p className={classes.mainTitle}>{localStorage.getItem('language')==='eng'? 'Contact': ' '}</p><p className={[classes.mainTitle,classes.bold].join(' ')}>{localStorage.getItem('language')==='eng'? 'Us': 'İletişim'}</p>
        </div>
        <div className={classes.infoDiv}>
          <p className={classes.title}>{localStorage.getItem('language')==='eng'? 'Office': 'Ofis'}</p>
          <p className={classes.subTitle}> Semper Teknoloji Araştırma ve Geliştirme Dan. San. ve Tic. A.Ş.</p>
          <p className={classes.info}> Sanayi Mh. Teknopark Bulvarı Teknopark İstanbul No: 1/3A Dk: 203 34912 Pendik/Istanbul </p>
          <p className={classes.tel}>Tel: </p><p className={classes.telNo}>  {'\b+90 216 504 02 84'} </p>
          <p className={classes.tel}>Tel: </p><p className={classes.telNo}> {'\b+90 216 504 13 35'} </p>
          <p className={classes.tel}>Fax: </p><p className={classes.telNo}> {'\b+90 216 504 02 74'} </p> 
          <a className={classes.tel}  href="mailto:info@semper-tech.com " > info@semper-tech.com </a> 

          <p className={classes.title2}>{localStorage.getItem('language')==='eng'? 'Career': 'Kariyer'}</p> 
          <p className={classes.info}>{localStorage.getItem('language')==='eng'? 'To get information about vacancies and job application, please send your resume and your cover letter to': 'İş başvuruları için lütfen özgeçmişinizi ve başvuru mektubunuzu career@semper-tech.com adresine iletiniz.'}</p>
          <a className={classes.email}  href="mailto:career@semper-tech.com" > career@semper-tech.com</a> 


	<p className={classes.title3}>{localStorage.getItem('language')==='eng'? 'Information Security Policy': 'Bilgi Güvenliği Politikası'}</p> 
	<p className={classes.info}>{localStorage.getItem('language')==='eng'? 'You can access to our information security policy from the following link.': 'Bilgi güvenliği politikamıza aşağıdaki bağlantıdan ulaşabilirsiniz.'}</p>
	<a className={classes.email} href={"policy"} target="_blank" rel="noopener noreferrer">{localStorage.getItem('language')==='eng'? 'Our Policy': 'Politikamız'}</a>


        </div>
        <div className={classes.messageDiv}>
          <p className={classes.messageTitle}>{localStorage.getItem('language')==='eng'? 'You can send us your message': 'Bize mesajınızı yollayabilirsiniz'}</p> 
          <label className={classes.label}>{localStorage.getItem('language')==='eng'? 'Name': 'İsim'}</label>
          <label className={[classes.label,classes.labelIn].join(' ')}>Email</label>
          <input className={[classes.input,classes.inputIn].join(' ')} onChange={this.nameContentHandle}/>
          <input className={classes.input} onChange={this.emailContentHandle}/>
          <label className={classes.label}>{localStorage.getItem('language')==='eng'? 'Subject': 'Konu'}</label>
          <input className={classes.input2} onChange={this.subjectContentHandle}/>
          <label className={classes.label}>{localStorage.getItem('language')==='eng'? 'Message': 'Mesajınız'}</label>
          <textarea className={classes.input3} onChange={this.messageContentHandle}/>
          <button onClick={this.HandleMail} className={classes.button}>{localStorage.getItem('language')==='eng'? 'Send': 'Gönder'}</button>
        </div>
        <div className={classes.maps}><iframe title="Map" className={classes.map} width="100%" height="500" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=601&amp;hl=en&amp;q=Semper%20Teknoloji+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe></div>
        <Footer  isTablet={this.props.isTablet} isMobile={this.props.isMobile} />
      </div>
    );
    }
}

export default Contact;
