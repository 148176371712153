import React, {Component} from 'react';
import apis from '../../api/index'
import PeopleInsert from './PeopleInsert';
import classes from './AdminPeople.module.css'
import PeopleUpdate from './PeopleUpdate'
import createPeople from '../../assets/images/createUser.png'
import imageHolder from '../../assets/images/imageHolder.png'
import DeletePeople from './DeletePeople'

class People extends Component  {
  constructor(props) {
    super(props);
    this.state={
    people:[],
    isLoading: false,
    create:false,
    update:false
    }
    this.createPeople = this.createPeople.bind(this)
  }
  componentDidMount(){
     apis.getAllPeople().then(a => {
            this.setState({
              people: a.data.data,
            }) 
        })
        localStorage.setItem("page","people")
    }
createPeople(){
    var temp=this.state.create
    this.setState({create:!temp})
  }
  render() {   
    let createPeopleDiv=(<button className={classes.createNew} onClick={this.createPeople}><img alt="img" className={classes.createImg} src={createPeople}/><p className={classes.createTitle}>CREATE NEW MEMBER </p></button>)
    if(this.state.create){
      createPeopleDiv=(<PeopleInsert/>)
    }

    return (
      <div>
          <p className={classes.Title}>Team Members</p>
        {createPeopleDiv}
   
            {this.state.people? this.state.people.map((user, index) => {
              const { _id, name,image, title} = user 
              return (
                  <div className={classes.adminDiv}  style={{ height:this.state.update===_id?'580px':'100px'}} key={_id}>
                  <img alt="img" src={image.length>0?image.substr(0,7)==='/images'? `../..${image}` :'data:image/jpeg;base64,'+image:imageHolder} className={classes.adminImg}  style={{ display:this.state.update===_id?'none':'inline'}}/>
                  <p className={classes.text}  style={{ display:this.state.update===_id?'none':'inline'}}>{name}</p>
                  <p className={classes.text2}  style={{ display:this.state.update===_id?'none':'inline'}}>{title}</p>
                 <DeletePeople id={_id} display={this.state.update===_id}/>
                 <button className={classes.update}  style={{ display:this.state.update===_id?'none':'inline'}}  onClick={()=>this.setState({update:_id})} >UPDATE</button>
                 {this.state.update===_id? <PeopleUpdate id={_id} />:<div></div>}
           </div>
              )
            }): <div>Nothing to show!</div>}  
       
        
      </div>
     
    );
    }
}

export default People;