import React, {Component} from 'react'
import classes from './Home.module.css'
import {
    BrowserRouter as Router,
    Switch,
    Route 
  } from "react-router-dom";
import semper from '../../assets/images/semperFooter.svg'
import linkedin from '../../assets/images/linkedin.svg'
import instagram from '../../assets/images/instagram.svg'
import facebook from '../../assets/images/facebook.svg'
import twitter from '../../assets/images/twitter.svg'
import linkedinBlue from '../../assets/images/linkedinBlue.svg'
import instagramBlue from '../../assets/images/instagramBlue.svg'
import facebookBlue from '../../assets/images/facebookBlue.svg'
import twitterBlue from '../../assets/images/twitterBlue.svg'
import linkedinColor from '../../assets/images/linkedinColor.svg'
import instagramColor from '../../assets/images/instagramColor.svg'
import facebookColor from '../../assets/images/facebookColor.svg'
import twitterColor from '../../assets/images/twitterColor.svg'

import About from '../../containers/About/About'
import Focus from '../../containers/Focus/Focus'
import Products from '../../containers/Products/Products'
import Contact from '../../containers/Contact/Contact'

class Footer extends Component  {
    constructor(props) {
        super(props);
        this.state={   
            linkedin:'https://www.linkedin.com/company/sempertech',
            instagram:'',
            facebook:'https://tr-tr.facebook.com/SemperTechnology',
            twitter:'', 
            language:'eng' ,
            imageLinkedin:linkedin,
            imageFacebook:facebook,
            imageInstagram:instagram,
            imageTwitter: twitter,
            imageLinkedinBlue:linkedinBlue,
            imageFacebookBlue:facebookBlue,
            imageInstagramBlue:instagramBlue,
            imageTwitterBlue: twitterBlue 
        }
      }
      HandleLink(e){  
        window.location.href = `/`+e.target.id
        localStorage.setItem('location', '/'+e.target.id); 
       
      }

getYear() {
    return new Date().getFullYear();
}
    render() {
        if(localStorage.getItem('language')!==this.state.language){
            this.setState({
                language:localStorage.getItem('language')
            })
           } 
         
       
       
        return (
            <footer >
                <div className={classes.FooterDiv}> 
                <div style={{display:this.props.isMobile? 'block':'none'}}>
                    <div className={classes.socialMedia}>
                        <img style={{display:this.state.linkedin!==''? 'block':'none'}}  onClick={()=>window.open(this.state.linkedin)} className={classes.logosFooter}  onMouseLeave={()=>{this.setState({imageLinkedin:linkedinBlue})}} onMouseOver={()=>this.setState({imageLinkedin:linkedinColor})} src={this.state.imageLinkedinBlue} alt="linkedin"/>
                        <img  style={{display:this.state.facebook!==''? 'block':'none'}}  onClick={()=>window.open(this.state.facebook)} className={classes.logosFooter}  onMouseLeave={()=>this.setState({imageFacebook:facebookBlue})} onMouseOver={()=>this.setState({imageFacebook:facebookColor})} src={this.state.imageFacebookBlue} alt="facebook"/>
                        <img  style={{display:this.state.instagram!==''? 'block':'none'}}  onClick={()=>window.open(this.state.instagram)} className={classes.logosFooter}  onMouseLeave={()=>this.setState({imageInstagram:instagramBlue})} onMouseOver={()=>this.setState({imageInstagram:instagramColor})} src={this.state.imageInstagramBlue}  alt="instagram"/>
                        <img  style={{display:this.state.twitter!==''? 'block':'none'}}  onClick={()=>window.open(this.state.twitter)} className={classes.logosFooter}  onMouseLeave={()=>this.setState({imageTwitter:twitterBlue})} onMouseOver={()=>this.setState({imageTwitter:twitterColor})} src={this.state.imageTwitterBlue} alt="twitter"/>
                    </div>
                   
                    <p className={classes.rights} >© {this.getYear()} Semper Tech. All Rights Reserved.</p>

                </div>
                    
                    <div className={classes.FooterDiv1}>
                        <img className={classes.logoFooter} src={semper} alt="semper"/>
                    </div>
                    <div className={classes.FooterDiv2} style={{display:this.props.isMobile? 'none':'block'}}>
                    <Router>
                        <ul>
                            
                           
                            <p className={classes.rights} >© {this.getYear()} Semper Tech. All Rights Reserved.</p>
                            <div className={classes.line}></div>
                            <img  style={{display:this.state.linkedin!==''? 'block':'none'}} onClick={()=>window.open(this.state.linkedin)} className={classes.logosFooter} onMouseLeave={()=>{this.setState({imageLinkedin:linkedin})}} onMouseOver={()=>this.setState({imageLinkedin:linkedinColor})} src={this.state.imageLinkedin} alt="linkedin"/>
                            <img  style={{display:this.state.facebook!==''? 'block':'none'}} onClick={()=>window.open(this.state.facebook)}  className={classes.logosFooter} onMouseLeave={()=>this.setState({imageFacebook:facebook})} onMouseOver={()=>this.setState({imageFacebook:facebookColor})} src={this.state.imageFacebook} alt="facebook"/>
                            <img  style={{display:this.state.instagram!==''? 'block':'none'}} onClick={()=>window.open(this.state.instagram)}  className={classes.logosFooter} onMouseLeave={()=>this.setState({imageInstagram:instagram})} onMouseOver={()=>this.setState({imageInstagram:instagramColor})} src={this.state.imageInstagram}  alt="instagram"/>
                            <img  style={{display:this.state.twitter!==''? 'block':'none'}} onClick={()=>window.open(this.state.twitter)}  className={classes.logosFooter} onMouseLeave={()=>this.setState({imageTwitter:twitter})} onMouseOver={()=>this.setState({imageTwitter:twitterColor})} src={this.state.imageTwitter} alt="twitter"/>
                        </ul>
                        <Switch>
                            <Route exact path="/hakkımızda" component={About} />
                            <Route exact path="/odaklarımız" component={Focus} />
                            <Route exact path="/ürünlerimiz" component={Products} />
                            <Route exact path="/iletişim" component={Contact} />
                        </Switch>
                    </Router>
                    
                    </div>
                </div>
            </footer>      
    );
    }
}


export default Footer;
