import React, {Component} from 'react';
import apis from '../../api/index'
import NewsInsert from './NewsInsert';
import classes from './AdminNews.module.css'
import createNews from '../../assets/images/createNews.png'
import NewsUpdate from './NewsUpdate'
import DeleteNews from './DeleteNews'

 

class News extends Component  {
  constructor(props) {
    super(props);
    this.state={
    news:[],
    isLoading: false,
    create:false,
    update:'',
    dragged:''
    }
    this.createNews = this.createNews.bind(this)
  }
  componentDidMount(){
     apis.getAllNews().then(a => {
            this.setState({
              news: a.data.data,
            })
        })
        localStorage.setItem("page","news")
    }
  createNews(){
    var temp=this.state.create
    this.setState({create:!temp})
  }
  onDragStart = (event) => {
    this.setState({dragged:event.target.dataset.position})
 }
 onDragOver = (event) => {
   if(event.target.dataset.position!==undefined){
     this.setState({drag:true})
     this.setState({dragedOver:event.target.dataset.position})
   }else{
     this.setState({dragedOver:null})
     this.setState({drag:false})
   }
   event.preventDefault();
 }
 onDrop= async  (event) => {
   event.preventDefault();
 
   if(this.state.drag){ 
     let e=event.target.dataset.position
     let dragged=this.state.news[this.state.dragged]
     let draggedOver=this.state.news[e] 
     var id=draggedOver._id
     var { text,image, date, source,title,baslik,metin }= dragged
     var payload = {id, text,image, date, source,title,baslik,metin }
     await apis.updateNewsById(id, payload).then(res => {

     })
     id=dragged._id
     text= draggedOver.text
     image= draggedOver.image
     date= draggedOver.date
     source= draggedOver.source
     title= draggedOver.title
     baslik= draggedOver.baslik
     metin= draggedOver.metin 

  
     payload = {id, text,image, date, source,title,baslik,metin } 
     await apis.updateNewsById(id, payload).then(res => {

   }) 
       this.setState(prevState => {
         let news = [...prevState.news];
         let index=this.state.dragged
         let temp = news[e];
         news[e ] = news[index];
         news[index] = temp;
       return { news }
     })
     this.setState({dragedOver:null})
     this.setState({drag:false})
     this.componentDidMount()
   }
   
 }
  render() {   
    let createProductDiv=(<button className={classes.createNew} onClick={this.createNews}><img alt="img" className={classes.createImg} src={createNews}/><p className={classes.createTitle}>CREATE NEW NEWS </p></button>)
    if(this.state.create){
      createProductDiv=(<NewsInsert/>)
    }
    
    return (
      <div>
       <p className={classes.Title}>News</p>
        {createProductDiv}
        <div className={classes.table}>
            {this.state.news? this.state.news.map((user, index) => {
              const { _id,title,} = user 
              return (
                <div draggable="true" data-position={index} onDragStart={this.onDragStart} onDragOver={this.onDragOver} onDrop={this.onDrop}  className={classes.newsDiv}  style={{ height:this.state.update===_id?'571px':'100px', opacity: this.state.dragedOver===index?'0.3':'1'}}  key={_id}>
                  <p className={classes.text}  style={{ display:this.state.update===_id?'none':'inline'}}>{title}</p>
                  <DeleteNews id={_id} display={this.state.update===_id}/>
                  <button className={classes.update}  style={{ display:this.state.update===_id?'none':'inline'}} onClick={()=>this.setState({update:_id})}>UPDATE</button>
                  {this.state.update===_id? <NewsUpdate id={_id} />:<div></div>}
                </div>
              )
            }): <div>Nothing to show!</div>}  
        </div>
        
      </div>
     
    );
    }
}

export default News;