import Footer from '../HomePage/Footer'
import React, {Component} from 'react';
import classes from './Focus.module.css'
import apis from '../../api/index'
import cyber from '../../assets/images/cyber.svg'
import aiImg from '../../assets/images/aiImg.svg'
import bigData from '../../assets/images/bigData.svg'
import future from '../../assets/images/future.svg'
import smartIot from '../../assets/images/smartIot.svg'

class Focus extends Component  {
  constructor(props) {
    super(props);
    this.state={ 
      products:[],
      bigObject:[],
      cyberObject:[],
      futureObject:[],
      smartObject:[],
      aiEng:'We believe Artificial Intelligence is at the core of all future technologies. With the help of digitalization and the availability of smaller and very powerful chipsets almost anything we use in our daily life will have computing capabilities. Thats why we put AI on the base of our products and build on top of it. Products that cover the topics like cyber security, predictive maintenance, smart energy solution, smart building, autonomous vehicle, smart simulator and machine learning-enabled virtual reality. ',
      aiTr:'Yapay Zekanın tüm gelecek teknolojilerin temelinde olduğuna inanıyoruz. Dijitalleştirme ve küçük ve çok güçlü yonga setleri sayesinde günlük hayatımızda kullandığımız her şey bilgisayar yeteneklerine sahip olacak. İşte bu nedenle yapay zekayı ürünlerimizin tabanına yerleştirip üzerine inşa ettik. Bu ürünler siber güvenlik, öngörücü bakım, akıllı enerji çözümü, akıllı bina, otonom araç, akıllı simülatör ve makine öğrenimi sağlayan sanal gerçeklik gibi konuları kapsar. ',
      cyberEng:'The focus of our cyber security team is to address intelligent security solutions to meet the challenges with increased complexity of information and network systems which are the backbone of modern society.\n\nThe way of doing is not just coming up with secure protocols for secure information transfer or conventional perimeter protection based on firewalls but also using the information of IT systems in order to defend against the incoming threats and attack types.\n\n In SemperTech, basic security principles (confidentiality, integrity, availability, access control and non-repudiation) are practiced and practically utilized. One of the distinguishing factors of our security solutions is utilizing Artificial Intelligence (mainly Machine Learning). This brings the brand new era of security and we believe it is going to reduce the consumption of human resources drastically in the future.',
      cyberTr:'Siber güvenlik ekibimizin odak noktası, modern toplumun belkemiğini oluşturan bilgi ve ağ sistemlerinin artan karmaşıklığı ile zorlukların üstesinden gelmek için akıllı güvenlik çözümlerini ele almaktır.\n\nBunu başarmanın yolu sadece güvenli bilgi iletişimi için güvenlik protokolleri oluşturmak veya konvansiyonel güvenlik duvarı temelli koruma yöntemleri kullanmak değildir, ayrıca bilişim teknolojileri sistemlerinden edinilen bilgileri kullanarak gelebilecek tüm saldırı ve tehditlere karşı sistemleri savunmaktır.\n\nSemperTech\'te, temel güvenlik ilkeleri (gizlilik, bütünlük, erişilebilirlik, erişim kontrolü ve reddedilme) uygulanmakta ve aktif olarak kullanılmaktadır. Güvenlik çözümlerimizin belirleyici faktörlerinden biri, Yapay Zekayı (çoğunlukla Makine Öğrenimi) kullanmasıdır. Bu, yepyeni güvenlik çağını bizi hazırlıyor ve gelecekte insan kaynaklarının tüketimini önemli ölçüde azaltacağına inanıyoruz.',
      bigTr1:'Büyük veri, büyük miktarda veriye atıfta bulunan terimdir. Günümüzün iş hayatında, günlük, haftalık veya farklı bir zaman araşığında üretilen veriler düzenli veya düzensiz olabilir. Global düzeyde üretilen ve işlenen verilerin hacmi inanılmaz derecede büyük ve gün geçtikçe büyümeye devam ediyor.',
      bigTr2:'Büyük veri analizinin temel avantajları, modern iş paradigmasında anahtar bileşenler olan hız ve verimliliktir. Veriler daha büyük ve daha ayrıntılı hale gelirken, daha iyi yönetim ve yeni fırsatlar yaratma açısından bu verilerden sonuç çıkarmak giderek daha da zorlaşıyor ve önemli hale geliyor.\n\nGünümüzde şirketler büyük veri analizlerinden üç ana yoldan yararlanabilirler. Verilerin toplanma ve yorumlanma maliyetleri düşürülebilir. Daha hızlı ve daha iyi karar verme prosedürüne ulaşılabilir. Son olarak; müşteri ihtiyaçları ve memnuniyetleri hakkında derinlemesine bilgi sahibi olma kabiliyeti ile şirketler daha etkili ve daha akıllı bir şekilde çalışabilirler.',
      bigEng1:'Big data is the term that is used to refer to the large volume of data. In today\'s business life, the data produced on a daily, weekly or any other timely basis can be structured or unstructured. The volume of the data that is being produced and processed on a global level is incredibly big and even keeps growing day-by-day.',
      bigEng2:'The main advantages of big data analytics are speed and efficiency which are the key components in modern business paradigm. As the data gets bigger, larger, and more detailed, the conclusion drawn from this data becomes more and more hard and important in terms of better management and creating new opportunities.\n\nToday, businesses can benefit from big data analytics in three main ways. The costs of gathering and interpretation of the data can be reduced. A faster and better decision making procedure can be attained. Lastly; with the ability to gain deeper insight about customer needs and satisfactions, companies can work in a more effective and smarter way.',
      futureEng:'The last decade has witnessed fast paced evolution of telecommunication networks, which is strongly driven by their positioning as main channel of delivery of most modern services of smart cities. The robust air interface, high performance, relatively easy integration to IP networks and availability of low cost multimedia terminals have created a growing interest towards a continued evolving pattern of the communication network technologies.The technological developments have freed the operators of being locked into proprietary platforms, where the operators were bound to an end-to-end knitted solution of incumbent vendors.\n\nFlexibility in network control and elasticity in infrastructure are the new prerequisites for a dynamic and policy driven network services, fitting ICT for smart cities, and achieving the envisioned convergence of heterogeneous technologies. The principles of new enabling technologies like Software Defined Networking (SDN) does not only enable the centralized control of overall infrastructure but also transform the network to a programmable and flexible network, which can dynamically be shared by the diverse set of network services. Yet, more flexibility is promised by the revolutionary and complementing concept of Network Function Virtualization (NFV) that moves the network functions from dedicated appliances to generic servers. In S-Labs, we carry out research on different aspects of 5G mobile networks, specifically: SDN, NFV, Cloud computing, and imparting intelligence in the networks by making use of Agent technologies and machine learning.',
      futureTr:'Son on yılda telekomünikasyon şebekelerinin hızla gelişen evrimine şahit olduk. Bunun sebebi de bu şebekelerin kendilerini en modern akıllı şehir uygulamalarının merkez kanalı olmak için çalışmaları. Yüksek performanslı, IP ağlara kolay entegre edilen ve düşük maliyetli çokluortam terminalleri sağlayan kablosuz ağ arayüzü olmaları bu yöne ilgi uyandırdı ve iletişim ağları teknolojisinin sürekli gelişen bir yapıya dönüştürdü. Teknolojik gelişmeler operatörleri tescilli platformlar olmaktan ve uçtan-uca bağlanmış hizmet sağlamaya yükümlü satıcılar olmaktan kurtardı.',
      smartEng:'The Internet of things (IoT) is the interconnection network which things are connected to each other or to some bigger systems.\n\nEnergy consumption can be monitored, smart grids can be constructed with IoT which results in a more secure and efficient energy consumption and decrease in customer expenses.\n\nWe can organize traffic better with IoT and intervene emergency situations in a faster and effective manner. Thus, we can create secure cities.\n\nWe can administer and monitor house services such as lightining, heating, entertainment and security remotely and create construct smart homes.',
      smartTr:'Nesnelerin interneti (IoT) nesnelerin birbirleriyle veya daha büyük sistemlerle bağlantıya geçmesidir.\n\nEnerji tüketimi takip edilebilir, IoT cihazlar ile akıllı şebekeler oluşturulabilir. Böylece daha güvenli ve verimli enerji tüketimi sağlanabilir ve müşterinin masrafları düşürülebilir.\n\nIoT ile trafiği daha iyi organize edebilir ve acil durumlara daha hızlı ve etkili bir şekilde müdahale edebiliriz. Böylece, güvenli şehirler oluşturabiliriz.\n\nAydınlatma, ısıtma, eğlence ve güvenlik gibi ev hizmetlerini uzaktan yönetebilir ve izleyebiliriz ve bu sayede daha akıllı evler yaratabiliriz.'
    }
  }
 
  componentDidMount(){
    if(localStorage.getItem('location')!=='/focus'){
      localStorage.setItem('location', '/focus'); 
    }
      
      apis.getAllProducts().then(a => {
        this.setState({
            products: a.data.data,
            
        })
        var newData = this.state.cyberObject.concat([a.data.data.filter(({ status }) => status === 'cyber' )]); 
        var newData2 = this.state.futureObject.concat([a.data.data.filter(({ status }) => status === 'future' )]); 
        var newData3 = this.state.bigObject.concat([a.data.data.filter(({ status }) => status === 'big' )]); 
        var newData4 = this.state.smartObject.concat([a.data.data.filter(({ status }) => status === 'smart' )]); 
 
        this.setState({
          cyberObject: newData[0],
          futureObject:  newData2[0],
          bigObject:  newData3[0],
          smartObject:   newData4[0],
      }) 
       
    })
  }
  render() {
     
    return (
      <div style={{transform: this.props.sideBar?'translateX(-34%)':'' }} className={classes.Div} onClick={this.props.outsideHandle}> 
      <div className={classes.head}></div>
      <div className={classes.titleDiv}>
          <p className={[classes.mainTitle,classes.bold].join(' ') }>{localStorage.getItem('language')==='eng'? 'Our':'Odaklarımız'} </p><p className={classes.mainTitle}>{localStorage.getItem('language')==='eng'? 'Focus':''}</p>
        </div>
        <div className={classes.aiDiv}>
          <p className={classes.title}>{localStorage.getItem('language')==='eng'? 'At the core of ICT: AI':'Bilgi ve İletişim Teknolojilerinin Merkezi: Yapay Zeka'}</p>
          <p className={classes.text}>{localStorage.getItem('language')==='eng'? this.state.aiEng:this.state.aiTr}</p>
        </div>
        <img alt="img" src={aiImg} className={classes.aiImg}  />
        
        <div className={classes.Column}> 
        <div className={[classes.prodDiv,classes.cyberDiv].join(' ')}>
          <img alt="img" className={[classes.prodImg,classes.cyberImg].join(' ')} src={cyber}/> 
        </div>
        <div className={classes.TextDiv}>
          <p className={classes.BlueTitle}>{localStorage.getItem('language')==='eng'? 'Cyber':'Siber'}</p><p className={[classes.BlueTitle,classes.bold2].join(' ') }>{localStorage.getItem('language')==='eng'? 'Security':'Güvenlik'}</p>
          <p className={ classes.cyberText}>{localStorage.getItem('language')==='eng'? this.state.cyberEng:this.state.cyberTr}</p>
        </div>
        <div className={[classes.whiteDiv,classes.whiteCyber].join(' ')}>
          <div style={{display:this.state.cyberObject.length!==0? 'block': 'none'}} className={classes.prodList}>
            <div className={[classes.line,classes.line2].join(' ')}>  </div>
            <p className={ [classes.prodListTitle,classes.prodTitleCyber].join(' ')}>{localStorage.getItem('language')==='eng'? 'Products':'Ürünlerimiz'}</p> 
            <div className={classes.productsDivLeft}>
            {this.state.cyberObject!==[]? this.state.cyberObject.map((user, index) => {
              const {  name } = user
              return (
                <p key={index } className={classes.prodNameLeft} >{name}</p>
              )}): <div>dsfsd</div>}
              </div>
          </div>
        </div>
        
        </div>
        
        <div className={classes.Column}> 
        <div className={classes.TextDiv}>
          <p className={[classes.BlueTitle,classes.bold3,classes.bigColor].join(' ')}>{localStorage.getItem('language')==='eng'? 'Big Data':'Büyük Veri'}</p><p className={[classes.BlueTitle,classes.bigColor ].join(' ') }>{localStorage.getItem('language')==='eng'? '& Data Analytics':'& Veri Analizi'}</p>
          <p className={[ classes.bigText,classes.bigText2].join(' ')}>{localStorage.getItem('language')==='eng'? this.state.bigEng1:this.state.bigTr1}</p>
          <p className={ [classes.bigText,classes.bold4].join(' ')}>{localStorage.getItem('language')==='eng'? 'Why this is important?':'Neden önemli?'}</p>
          <p className={ classes.bigText}>{localStorage.getItem('language')==='eng'? this.state.bigEng2:this.state.bigTr2}</p>
        </div>
        <div className={[classes.prodDiv,classes.bigDiv].join(' ')}>
          <img alt="img" className={[classes.prodImg,classes.bigImg].join(' ')} src={bigData}/> 
        </div>
        <div className={[classes.whiteDiv,classes.whiteLeft,classes.whiteBig].join(' ')}>
          <div style={{display:this.state.bigObject.length!==0? 'block': 'none'}} className={[classes.prodList,classes.prodListLeft].join(' ')}>
            <div className={classes.line}>  </div>
            <p className={ classes.prodListTitle}>{localStorage.getItem('language')==='eng'? 'Products':'Ürünlerimiz'}</p> 
            <div className={classes.productsDiv}>
            {this.state.bigObject!==[]? this.state.bigObject.map((user, index) => {
              const { name } = user
              return (
                <p key={index } className={classes.prodName} >{name}</p>
              )}): <div> </div>}
              </div>
          </div>
          </div>
        </div>
       
        <div className={classes.Column}> 
        <div className={[classes.prodDiv,classes.futureDiv].join(' ')}>
          <img alt="img" className={[classes.prodImg,classes.futureImg].join(' ')} src={future}/> 
        </div>
        <div className={classes.TextDiv}>
          <p className={[classes.BlueTitle,classes.bold5].join(' ') }>{localStorage.getItem('language')==='eng'? 'Future':'Gelecek Nesil'}</p><p className={[classes.BlueTitle,classes.futureColor].join(' ') }>{localStorage.getItem('language')==='eng'? 'Mobile Networks':'Mobil Ağlar'}</p>
          <p className={ [classes.cyberText,classes.futureText].join(' ')}>{localStorage.getItem('language')==='eng'? this.state.futureEng:this.state.futureTr}</p>
        </div>
        <div style={{marginTop:localStorage.getItem('language')==='eng'?'-518px':'-54px' }} className={[classes.whiteDiv,classes.whiteFuture].join(' ')}>
          <div style={{display:this.state.futureObject.length!==0? 'block': 'none'}}  className={classes.prodList}>
            <div className={[classes.line,classes.line2].join(' ')}>  </div>
            <p className={ [classes.prodListTitle,classes.prodsTitleFuture].join(' ')}>{localStorage.getItem('language')==='eng'? 'Products':'Ürünlerimiz'}</p> 
            <div className={[classes.productsDivLeft ,classes.prodsDiv2].join(' ')}>
            {this.state.futureObject!==[]? this.state.futureObject.map((user, index) => {
              const { name } = user
              return (
                <p key={index } className={classes.prodNameLeft} >{name}</p>
              )}): <div>dsfsd</div>}
              </div>
          </div>
        </div>
        </div>
        
        <div className={classes.Column}> 
        <div className={classes.TextDiv}>
          <p className={ [classes.BlueTitle,classes.smartColor].join(' ')  }>{localStorage.getItem('language')==='eng'? 'Smart Buildings':'Akıllı Binalar'}</p><p className={[classes.BlueTitle,classes.bold4,classes.smartColor,classes.iotText ].join(' ') }> & IoT </p>
          <p className={ [classes.bigText,classes.smartText].join(' ')}>{localStorage.getItem('language')==='eng'? this.state.smartEng:this.state.smartTr}</p> 
        </div>
        <div className={[classes.prodDiv,classes.smartDiv].join(' ')}>
          <img alt="img" className={[classes.prodImg,classes.smartImg].join(' ')} src={smartIot}/> 
        </div>
        <div className={[classes.whiteDiv,classes.whiteSmart].join(' ')}>
          <div  style={{display:this.state.smartObject.length!==0? 'block': 'none'}} className={[classes.prodList,classes.prodListLeft].join(' ')}>
            <div className={[classes.line,classes.line3].join(' ')}>  </div>
            <p className={ classes.prodListTitle}>{localStorage.getItem('language')==='eng'? 'Products':'Ürünlerimiz'}</p> 
            <div className={[classes.productsDiv,classes.productsDiv2].join(' ')}>
             {this.state.smartObject!==[]? this.state.smartObject.map((user, index) => {
              const { name } = user
              return (
                <p key={index } className={classes.prodName} >{name}</p>
              )}): <div></div>}
               </div>
          </div>
        </div>
        </div>
       <Footer  isTablet={this.props.isTablet} isMobile={this.props.isMobile} />
      </div>
    );
    }
}

export default Focus;