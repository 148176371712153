import React, {Component} from 'react';
import apis from '../../api/index'
import AdminUsersInsert from './AdminUsersInsert';
import classes from './AdminUsers.module.css'
import createUser from '../../assets/images/createUser.png'
import AdminUsersUpdate from './AdminUsersUpdate'
import DeleteAdminUser from './DeleteAdminUser'

class AdminUsers extends Component  {
  constructor(props) {
    super(props);
    this.state={
    adminUsers:[],
    isLoading: false,
    create:false,
    update:''
    }
    this.createUser = this.createUser.bind(this)
  }
  componentDidMount(){
     apis.getAllAdminUsers().then(a => {
            this.setState({
              adminUsers: a.data.data,
            }) 
        })
        localStorage.setItem("page","user")
    }
  createUser(){
    var temp=this.state.create
    this.setState({create:!temp})
  }
  render() {   
    let createUserDiv=(<button className={classes.createNew} onClick={this.createUser}><img alt="img" className={classes.createImg} src={createUser}/><p className={classes.createTitle}>CREATE NEW ADMIN </p></button>)
    if(this.state.create){
      createUserDiv=(<AdminUsersInsert/>)
    }
    return (
      <div>
        <p className={classes.Title}>Admins</p>
        {createUserDiv}
          <div className={classes.table}>
             {this.state.adminUsers? this.state.adminUsers.map((user, index) => {
              const { _id, username} = user 
              const adminText = username.toString()
              return (
                <div className={classes.adminDiv} style={{ height:this.state.update===_id?'209px':'100px'}} key={_id}>
                  <p className={classes.adminImg} style={{ display:this.state.update===_id?'none':'inline'}}>  {adminText.substring(0,1)}</p>
                  <p className={classes.text} style={{ display:this.state.update===_id?'none':'inline'}}>{username}</p>
                  <DeleteAdminUser display={this.state.update===_id} id={_id}/>
                  <button className={classes.update} style={{ display:this.state.update===_id?'none':'inline'}} onClick={()=>this.setState({update:_id})}>UPDATE</button>
                  {this.state.update===_id? <AdminUsersUpdate  id={_id} />: <div></div>}
                 
                </div>
              )
            }): <div>Nothing to show!</div>}  
 
        
          </div>
           
      </div>
     
    );
    }
}

export default AdminUsers;