import React, {Component} from 'react'; 
import classes from './Products.module.css'
import apis from '../../api/index'
import Footer from '../HomePage/Footer'

class Products extends Component  {
  constructor(props) {
    super(props);
    this.state={
    products:[],
    language:'eng' 
    } 
  } 
 
  componentDidMount(){
    if( localStorage.getItem('location')!=='/products'){
      localStorage.setItem('location', '/products'); 
    }
      apis.getAllProducts().then(a => {
        this.setState({
            products: a.data.data,
        })
    })
  }
  render() {
    return (
      <div className={classes.divAll} style={{transform: this.props.sideBar?'translateX(-34%)':'' }} onClick={this.props.outsideHandle}>
        <div className={classes.head}></div>
        <p className={[classes.title,classes.bold ].join(' ')}>{localStorage.getItem('language')==='eng'? 'Our':'Ürünlerimiz '} </p><p className={classes.title}>{localStorage.getItem('language')==='eng'? 'Products':''}</p>
         {this.state.products.map((user, index) => {
            const { _id, text, logo, HImage,link, hakkinda} = user 
            return (
              <div key={_id}>
                  <div className={classes.productDiv}>
                    <div className={classes.textDiv}>
                      <img  alt="img" className={classes.logo} src={`../..${logo }`}/>
                      <p  className={classes.about}> {localStorage.getItem('language')==='eng'? text:hakkinda} </p> 
                      <button style={{display: link? 'block': 'none'}}  onClick={()=>window.open(link)} className={classes.button}>{localStorage.getItem('language')==='eng'? 'More Info':'Daha Fazla Bilgi'}</button>
                     </div> 
                     <img alt="img" className={classes.HImage} src={`../..${HImage }`}/>
                  </div>
                
              </div>
          );
          })}
            <Footer  isTablet={this.props.isTablet} isMobile={this.props.isMobile} />
      </div>
    );
    }
}

export default Products;