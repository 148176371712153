import React, {Component} from 'react';
import apis from '../api/index'
import AdminUsers from './AdminUsers/AdminUsers'
import AdminProducts from './AdminProducts/AdminProducts'
import AdminPeople from './AdminPeople/AdminPeople'
import AdminNews from './AdminNews/AdminNews'
import AdminClients from './AdminClients/AdminClients'
import classes from './Admin.module.css';

import admins from "../assets/images/admins.png"
import products from "../assets/images/products.png"
import teamMembers from "../assets/images/teamMembers.png"
import news from "../assets/images/news.png"
import clients from "../assets/images/clients.png"
import semper from '../assets/images/semper.svg'

import leftArrow from "../assets/images/leftArrow.png"

class Admin extends Component  {
  constructor(props) {
    super(props);
    this.state={
    page:"user"
    }
     this.handleUser = this.handleUser.bind(this)
     this.handleProducts = this.handleProducts.bind(this)
     this.handlePeople = this.handlePeople.bind(this)
     this.handleNews = this.handleNews.bind(this)
     this.handleClients = this.handleClients.bind(this)
  }
  logoutHandle(){
    localStorage.removeItem("auth")
    window.location.href='/admin'
  }
   handleUser(){
      this.setState({ page:"user" })
      localStorage.setItem("page","user")
      window.location.href = `/admin/adminUsers`
    }
    handleProducts(){
      this.setState({ page:"product" })
      localStorage.setItem("page","product")
      window.location.href = `/admin/products`
 
    }
    handlePeople(){
      this.setState({ page:"people" })
      localStorage.setItem("page","people")
      window.location.href = `/admin/people`
    }
    handleNews(){
      this.setState({ page:"news" })
      localStorage.setItem("page","news")
      window.location.href = `/admin/news`
    }
    handleClients(){
      this.setState({page:"clients" })
      localStorage.setItem("page","clients")
      window.location.href = `/admin/clients`
    }

    componentDidMount= async ()=>{
      if(localStorage.getItem("auth")){
        await apis.verifyAdminUser({headers:{"x-auth-token":JSON.parse(localStorage.getItem("auth")).value,}}).then(res => {
            var object = JSON.parse(localStorage.getItem("auth")),
            dateString = object.timestamp,
            now = new Date().getTime().toString();
            var msec=now-dateString 
            var minutes = Math.floor(msec / 60000); 
            if(minutes>30){
                localStorage.removeItem("auth")
                window.location.href='/admin'
            }
        })
      }else{
        window.location.href='/admin' 
      }
   
  }  
  render() {
    if(!localStorage.getItem("page")){
      localStorage.setItem("page",this.state.page)
    }
    let div  
    if(this.props.route==="user"){
      localStorage.setItem("page","user")
    }else if(this.props.route==="product"){
      localStorage.setItem("page","product")
    }else if(this.props.route==="news"){
      localStorage.setItem("page","news")
 
    }else if(this.props.route==="people"){
      localStorage.setItem("page","people")
 
    }else if(this.props.route==="clients"){
      localStorage.setItem("page","clients")
    }


    if(localStorage.getItem("page")==="user" ){
      
      div=(<AdminUsers/>) 
    }else if (localStorage.getItem("page")==="news" ){ 
      div=(<AdminNews/>)
    }else if( localStorage.getItem("page")==="product"){ 
      div=(<AdminProducts />)
    }else if( localStorage.getItem("page")==="people"){ 
      div=(<AdminPeople/>)
    }else if( localStorage.getItem("page")==="clients"){
      div=(<AdminClients/>)
    }
    return (
      
      <div >
        <div className={classes.sideBar}>
          <img alt="semper" className={classes.semperTitle} src={semper}/>
          <button style={{backgroundColor: localStorage.getItem("page")==="user"? '#f2f4fa': '#ffffff'}} className={classes.AdminButtons} onClick={this.handleUser}><img  alt="admins" className={classes.sidebarImg} src={admins}/> <p style={{color: localStorage.getItem("page")==="user"? '#3b435e': '#0060ad'}}  className={classes.sidebarText}>Admins</p></button>
          <button style={{backgroundColor: localStorage.getItem("page")==="product"? '#f2f4fa': '#ffffff'}} className={classes.AdminButtons} onClick={this.handleProducts}><img alt="products" className={classes.sidebarImg} src={products}/><p style={{color: localStorage.getItem("page")==="product"? '#3b435e': '#0060ad'}} className={classes.sidebarText}>Products</p> </button>
          <button style={{backgroundColor: localStorage.getItem("page")==="people"? '#f2f4fa': '#ffffff'}} className={classes.AdminButtons} onClick={this.handlePeople}><img alt="members" className={classes.sidebarImg} src={teamMembers}/><p style={{color: localStorage.getItem("page")==="people"? '#3b435e': '#0060ad'}} className={classes.sidebarText}>Team Members</p> </button>
          <button style={{backgroundColor: localStorage.getItem("page")==="news"? '#f2f4fa': '#ffffff'}} className={classes.AdminButtons} onClick={this.handleNews}><img alt="news" className={classes.sidebarImg} src={news}/><p style={{color: localStorage.getItem("page")==="news"? '#3b435e': '#0060ad'}} className={classes.sidebarText}>News</p></button>
          <button style={{backgroundColor: localStorage.getItem("page")==="clients"? '#f2f4fa': '#ffffff'}} className={classes.AdminButtons} onClick={this.handleClients}><img alt="clients" className={classes.sidebarImg} src={clients}/><p style={{color: localStorage.getItem("page")==="clients"? '#3b435e': '#0060ad'}} className={classes.sidebarText}>Clients</p></button>
        </div>
        <div className={classes.mainDiv}>
          {div}
        </div>
        <button className={classes.logout} onClick={this.logoutHandle}>LOGOUT</button>
        <div className={classes.rightBar}>
          <div className={classes.rightButton}>
            <img  alt="<" className={classes.rightImg} src={leftArrow}/>
            <p className={classes.p}>A</p>
          </div>
        </div>
        
          
      </div>
    );
    }
}

export default Admin;