import React, {Component} from 'react';
import apis from '../../api/index'
import ProductsInsert from './ProductsInsert';
import classes from './AdminProducts.module.css'
import createProduct from '../../assets/images/createProduct.png'
import ProductUpdate from './ProductsUpdate'
import DeleteProducts from './DeleteProduct'

class Products extends Component  {
  constructor(props) {
    super(props);
    this.state={
    products:[],
    isLoading: false,
    create:false,
    update:'',
    dragged:''
    }
    this.createProducts = this.createProducts.bind(this)
  }
  componentDidMount(){
     apis.getAllProducts().then(a => {
            this.setState({
              products: a.data.data,
            })
        })
        localStorage.setItem("page","product")
        
    }
  createProducts(){
    var temp=this.state.create
    this.setState({create:!temp})
  }
   onDragStart = (event) => {
     this.setState({dragged:event.target.dataset.position})
  }
  onDragOver = (event) => {
    if(event.target.dataset.position!==undefined){
      this.setState({drag:true})
      this.setState({dragedOver:event.target.dataset.position})
    }else{
      this.setState({dragedOver:null})
      this.setState({drag:false})
    }
    event.preventDefault();
  }
  onDrop= async  (event) => {
    event.preventDefault();
  
    if(this.state.drag){ 
      let e=event.target.dataset.position
      let dragged=this.state.products[this.state.dragged]
      let draggedOver=this.state.products[e] 
      var id=draggedOver._id
      var {  text,name,  status,logo, HImage,link,update,hakkinda,isim } = dragged
      var payload = {id, text,name,  status,logo, HImage,link,update,hakkinda,isim}
      await apis.updateProductsById(id, payload).then(res => {

      })
      id=dragged._id
      text = draggedOver.text
      name = draggedOver.name 
      status = draggedOver.status
      logo = draggedOver.logo
      HImage = draggedOver.HImage
      link = draggedOver.link
      update = draggedOver.update
      hakkinda = draggedOver.hakkinda
      isim = draggedOver.isim
      payload = {id, text,name,  status,logo, HImage,link,update,hakkinda,isim }
      await apis.updateProductsById(id, payload).then(res => {

    }) 
        await this.setState(prevState => {
          let products = [...prevState.products];
          let index=this.state.dragged
          let temp = products[e];
          products[e ] = products[index];
          products[index] = temp;
        return { products }
      })
      this.setState({dragedOver:null})
      this.setState({drag:false})
      this.componentDidMount()
    
    }
    
  }
  
  render() {  
    let createProductDiv=(<button className={classes.createNew} onClick={this.createProducts}><img alt="img" className={classes.createImg} src={createProduct}/><p className={classes.createTitle}>CREATE NEW PRODUCT </p></button>)
    if(this.state.create){
      createProductDiv=(<ProductsInsert/>)
    }
    
    return (
      <div>
        <p className={classes.Title}>Products</p>
        {createProductDiv}
        <div className={classes.table}>
            {this.state.products? this.state.products.map((user, index) => {
            const { _id, name } = user 
              return (
                <div draggable="true" data-position={index} onDragStart={this.onDragStart} onDragOver={this.onDragOver} onDrop={this.onDrop} className={classes.productDiv}  style={{ height:this.state.update===_id?'544px':'100px', opacity: this.state.dragedOver===index?'0.3':'1'}} key={_id}>
                  <p className={classes.text} style={{ display:this.state.update===_id?'none':'inline'}}>{name}</p>
                  <DeleteProducts id={_id} display={this.state.update===_id}/>
                  <button className={classes.update}  style={{ display:this.state.update===_id?'none':'inline'}} onClick={()=>this.setState({update:_id})}>UPDATE</button>
                  {this.state.update===_id? <ProductUpdate id={_id} />:<div></div>}
                </div>
              )
            }): <div>Nothing to show!</div>}  
          </div>
        
      </div>
     
    );
    }
}

export default Products;