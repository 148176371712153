import React, {Component} from 'react'
import classes from './Home.module.css'
import apis from '../../api/index'
import Slider from "react-slick"; 

class Products extends Component  {
    constructor(props) {
        super(props);
        this.state={
        products:[],
        language:'eng' 
        }
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
      } 
      next() {
        this.slider.slickNext();
      }
      previous() {
        this.slider.slickPrev();
      }
    componentDidMount(){
        apis.getAllProducts().then(a => {
            this.setState({
                products: a.data.data,
            })
        })
        }
    render() {
        if(localStorage.getItem('language')!==this.state.language){
            this.setState({
                language:localStorage.getItem('language')
            })
           }
        const settings = {
            dots: true,
            swipeToSlide: true,
            infinite: true,
            speed: 1700,
            autoplaySpeed:6000,
            slidesToShow: 1,
            autoplay:true,
            slidesToScroll: 1
        };
        return (
            <div className={classes.productsDiv} >
                <link
                    rel="stylesheet"
                    type="text/css"
                    charSet="UTF-8"
                    href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
                />
                <link
                    rel="stylesheet"
                    type="text/css"
                    href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
                />
                <div className={classes.productTitle}>
                <p className={[classes.productsText,classes.partnersTextbold,classes.width2].join(' ')}>{this.state.language==='eng'? 'Our':'Ürünlerimiz '} </p><p className={[classes.productsText,classes.width1].join(' ')}>{this.state.language==='eng'? 'Products':''}</p>
                </div>
                <Slider ref={c => (this.slider = c)} {...settings}>
                    {this.state.products.map((user, index) => {
                    const { _id, text,logo, HImage,link,hakkinda} = user 
                    return (
                        <div key={_id}>
                            <div className={classes.productT}>
                                <img alt="img" className={classes.logoProduct} src={`../..${logo }`}/>
                                <p  className={classes.aboutProduct}> {this.state.language==='eng'? text:hakkinda} </p>
                                <button style={{display: link? 'block': 'none'}} onClick={()=> window.open(link)} className={[classes.headerButton,classes.buttonProduct].join(' ')}>{this.state.language==='eng'? 'More Info':'Daha Fazla Bilgi'}</button>
                            </div>
                            <img alt="img" className={classes.HImage} src={`../..${HImage }`}/>
                        </div>
                    );
                    })}
                </Slider>
                   <button  onClick={this.previous} className={[classes.productsArrows,classes.leftProd].join(' ') }> </button>
                   <button  onClick={this.next} className={[classes.productsArrows,classes.rightProd].join(' ')}> </button>
            </div>      
    );
    }
}


export default Products;