import React, { Component } from 'react'
import apis from '../../api/index'
import classes from './AdminPeople.module.css'
import imageHolder from '../../assets/images/member.png'
import DeletePeople from './DeletePeople'

import tool1 from '../../assets/images/tool1.png' 
import example from '../../assets/images/example.png' 

class PeopleUpdate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            tooltip:1,
            id: this.props.id,
            name: '',
            text: '',
            image: '',
            title: '',
            email:'',
            link:'',
            gridx:1,
            gridy:1,
            people:[],
            hakkinda:'',
            unvan :'',
            thisImage: imageHolder, 
        }
    }

    handleChangeInputName = async event => {
        const name = event.target.value
        this.setState({ name })
    }

    handleChangeInputText = async event => {
        const text = event.target.value
        this.setState({ text })
    }
    
    handleChangeInputHakkinda= async event => {
        const hakkinda = event.target.value
        this.setState({ hakkinda })
    }
    
    handleChangeInputUnvan = async event => {
        const unvan = event.target.value
        this.setState({ unvan })
    }

    handleReaderImage=(e)=>{
        let str=e.target.result
        this.setState({image:btoa(str)})

    }
    handleChangeInputEmail = async event=>{
        let email = event.target.value
        this.setState({ email })

    }
  
    handleChangeInputImage (event) {
        const file = event.target.files[0]
        if(file){
            const reader=new FileReader()
            reader.onload=this.handleReaderImage.bind(this)
            reader.readAsBinaryString(file)
        }
    }
    handleChangeInputTitle = async event => {
        const title = event.target.value
        this.setState({ title })
    }

    handleChangeInputGridX = async event => {
        const gridx = event.target.value
        if(gridx===''){
            this.setState({ gridx })
        }else if(gridx<1){
            this.setState({ gridx:1 })
        }else if(gridx >=4){
            this.setState({ gridx:3 })
        }else{
             this.setState({ gridx })
        }
       
    }
    handleChangeInputGridY = async event => {
        const gridy = event.target.value
        if(gridy===''){
            this.setState({ gridy })
        }else if(gridy<1){
            this.setState({ gridy:1 })
        }else {
            this.setState({ gridy })
        }
    }
    handleUpdatePeople = async () => {
        var v=false
        if(localStorage.getItem("auth")){
            await apis.verifyAdminUser({headers:{"x-auth-token":JSON.parse(localStorage.getItem("auth")).value,}}).then(res => {
                var object = JSON.parse(localStorage.getItem("auth")),
                dateString = object.timestamp,
                now = new Date().getTime().toString();
                var msec=now-dateString 
                var minutes = Math.floor(msec / 60000); 
                if(minutes>30){
                    localStorage.removeItem("auth")
                    window.location.href='/admin'
                }else{
                    v=true
                }
            })
          }else{
            window.location.href='/admin' 
          }
          if(v){
            const { id, name,text,image,title,link,email,gridx,gridy,hakkinda,unvan } = this.state
            const payload = {name,text,image,title,link,email,gridx,gridy,hakkinda,unvan }
            let people=[]
            await apis.getAllPeople().then(a => {
                people= a.data.data 
            })
            var i;
            let update=true
            for (i = 0; i < people.length; i++) { 
                if (id!==people[i]._id && gridx===people[i].gridx && gridy===people[i].gridy){
                
                    update=false
                }            
            }
            if(update){
                await apis.updatePeopleById(id, payload).then(res => {
                window.alert(`Team Member updated successfully`)
                this.setState({
                    name: '',
                    text: '',
                    image: '',
                    title: '',
                })
            })
            
            window.location.href = `/admin/people`
            }else{
                window.alert(`ALERT : Team Member must have a unique grid coordinate!`)
            }
          }
        
    } 
    handleChangeInputLink = async event => {
        const link = event.target.value
        this.setState({ link})
    }
    componentDidMount = async () => {
        
        const { id } = this.state
        const people = await apis.getPeopleById(id)

        this.setState({
            name: people.data.data.name,
            text: people.data.data.text,
            link: people.data.data.link,
            image: people.data.data.image,
            title: people.data.data.title,
            email: people.data.data.email,
            gridx: people.data.data.gridx,
            gridy: people.data.data.gridy,
            hakkinda: people.data.data.hakkinda,
            unvan: people.data.data.unvan,
        })
    }
    next=()=>{
        this.setState({tooltip:2})
    }
    prev=()=>{
        this.setState({tooltip:1})
    }
    render() {
        const {id, name,text,image,title,email,link,gridx,gridy,hakkinda,unvan} = this.state
        return (
            <div className={classes.updateDiv}> 
            <p className={classes.createTitle2}>UPDATE TEAM MEMBER</p>
            <div className={classes.div1}>
                 <label  className={classes.label}>Photo </label>
                 <div className={classes.hoverDiv2}>
                         <span  className={classes.tooltip}>
                            <div style={{display:this.state.tooltip===1?'block': 'none'}}> 
                                <img className={classes.toolImg } src={tool1} alt ="tool"/>
                                <p className={classes.toolText}> Grid system helps placing team members in the web page</p>
                                <p className={classes.toolText}> <strong>1)</strong> Y coordinate of the team member place</p>
                                <p className={classes.toolText}><strong>2)</strong> X coordinate of the team member place (max 3)</p>
                             </div>
                             <div style={{display:this.state.tooltip===2?'block': 'none'}}>
                                <p className={classes.toolText}> Example: Grid (3 , 1)</p>
                                <img className={classes.toolExample } src={example} alt ="tool"/>

                             </div>

                             <button className={classes.left } onClick={this.prev} >&#x1F808;</button>
                             <button className={classes.right } onClick={this.next}>	&#x1F80A;</button> 
                         </span>
                    </div>
                 <label  className={[classes.label,classes.grid].join(' ')}>Grid</label>
                
                 <img alt="img" className={classes.logo} src={image.length>0?image.substr(0,7)==='/images'? `../..${image}` :'data:image/jpeg;base64,'+image:imageHolder}/> <input
                     className={[classes.input,classes.gridIn].join(' ')}
                     type="number"
                     value={gridy}
                     min="0"
                     onChange={this.handleChangeInputGridY}
                 />
                 <p className={classes.x}>&#10006;</p>
                 <input
                     className={[classes.input,classes.gridIn2].join(' ')}
                     type="number"
                     value={gridx}
                     min="0" max="2"
                     onChange={this.handleChangeInputGridX}
                 />
                 <input
                  className={classes.dosyasec}
                 type="file"
                 onChange={(event) => this.handleChangeInputImage(event)}
             />
             <label className={classes.upload}> UPLOAD</label>
          
            
            </div>
            <div className={classes.div2}> 
                 <label className={classes.label}>Name </label>
                 <input
                     className={classes.input}
                     type="text"
                     value={name}
                     onChange={this.handleChangeInputName}
                 />

                 <label className={classes.label}>Title </label>
                 <input
                 className={classes.input}
                     type="text"
                     value={title}
                     onChange={this.handleChangeInputTitle}
                 />
                 <label className={classes.label}>Unvan </label>
                 <input
                     className={classes.input}
                     type="text"
                     value={unvan}
                     onChange={this.handleChangeInputUnvan}
                 />
                 <span  className={[classes.label,classes.link].join(' ')}>Link </span>
                 <input
                     className={[classes.input,classes.inputLink].join(' ')}
                     type="text"
                     value={link}
                     onChange={this.handleChangeInputLink}
                 /> 
            </div>
            <div className={classes.div3} >   
           
            <label  className={classes.label}>About </label>
             <textarea rows="5" cols="40"
              className={[classes.inputT, classes.input].join(' ')}
                 type="text"
                 value={text}
                 onChange={this.handleChangeInputText}
             />  
              <label  className={classes.label}>Hakkında </label>
             <textarea rows="5" cols="40"
              className={[classes.inputT, classes.input].join(' ')}
                 type="text"
                 value={hakkinda}
                 onChange={this.handleChangeInputHakkinda}
             /> 
              <label className={classes.label}>E-mail </label>
                 <input
                 className={[classes.input,classes.email].join(' ')}
                     type="text"
                     value={email}
                     onChange={this.handleChangeInputEmail}
                 />
                 <DeletePeople id={id} update={true}/>
            <button className={classes.createButton} onClick={this.handleUpdatePeople}>UPDATE PEOPLE</button>
             </div>
    
               
       
            </div>
        )
    }
}

export default PeopleUpdate
