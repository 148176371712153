import React, {Component} from 'react';
import apis from '../../api/index' 
import classes from './AdminUsers.module.css' 


class DeleteAdminUser extends Component {
  deleteUser = async(event) => {
      event.preventDefault()
      var v=false
      if(localStorage.getItem("auth")){
          await apis.verifyAdminUser({headers:{"x-auth-token":JSON.parse(localStorage.getItem("auth")).value,}}).then(res => {
              var object = JSON.parse(localStorage.getItem("auth")),
              dateString = object.timestamp,
              now = new Date().getTime().toString();
              var msec=now-dateString 
              var minutes = Math.floor(msec / 60000); 
              if(minutes>30){
                  localStorage.removeItem("auth")
                  window.location.href='/admin'
              }else{
                  v=true
              }
          })
        }else{
          window.location.href='/admin' 
        } 
      if(v){
        if (window.confirm(`Do tou want to delete the user ${this.props.id} permanently?`,)) {
          apis.deleteAdminUserById(this.props.id)
          window.location.reload()
          }
      }
  }

  render() {
    if(!this.props.display){
      return <button className={this.props.update===true?classes.delete2:classes.delete} onClick={this.deleteUser}>DELETE</button>
    }else{
      return <div></div>
    }
      
  }
}
export default DeleteAdminUser