import React, {Component} from 'react'
import classes from './Home.module.css'
import apis from '../../api/index'
import Slider from "react-slick"; 


class News extends Component  {
    constructor(props) {
        super(props);
        this.state={
        news:[],
        nav1: null,
        nav2: null,
        language:'eng' 
        }
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
       
      } 
    componentDidMount(){ 
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2
          });
        apis.getAllNews().then(a => {
            this.setState({
                news: a.data.data,
            })
        })
      
    }
   
    next() {
        if(this.slider){
            this.slider.slickNext(); 
        }else{
            this.slider1.slickNext();
            this.slider2.slickNext();
        }
    }
    previous() {
        if(this.slider){
            this.slider.slickPrev(); 
        }else{
             this.slider1.slickPrev();
            this.slider2.slickPrev();
        }
    }
         
    render() {
        if(localStorage.getItem('language')!==this.state.language){
            this.setState({
                language:localStorage.getItem('language')
            })
           }
        const settings = {
            dots: true,
            speed: 1700,
            autoplay:true,
            autoplaySpeed:6000,
            slidesToShow: 1, 
            slidesToScroll: 1, 
            swipeToSlide: true,
            variableWidth: true,
         
        };
        if(this.props.isMobile){
            return (
                <div className={classes.NewsDiv}>
                    <link
                        rel="stylesheet"
                        type="text/css"
                        charSet="UTF-8"
                        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
                    />
                    <link
                        rel="stylesheet"
                        type="text/css"
                        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
                    />
                    <div className={classes.NewsTitle}>
                    <p className={[classes.partnersText,classes.partnersTextbold,classes.newsText].join(' ')}>{this.state.language==='eng'? 'News':'Haberlerimiz '} </p>
                    </div> 
                        <Slider  asNavFor={this.state.nav2}    ref={slider => (this.slider1 = slider)}  {...settings}>
                            {this.state.news.map((user, index) => {
                            const { _id, image,  } = user 
                            return (
                                <div key={_id}>
                                    <img alt="img" className={classes.imageNews} src={`../..${image }` }/>
                                    
                                </div>
                            );
                            })}
                            </Slider>
                            <Slider asNavFor={this.state.nav1}   ref={slider => (this.slider2 = slider)} {...settings}>
                            {this.state.news.map((user, index) => {
                            const { _id, text,title, date,baslik,source,  metin} = user 
                            return (
                                <div key={_id}> 
                                    <div className={[classes.NewsT,classes.newsT].join(' ')}>
                                        <p className={classes.titleNews}> {this.state.language==='eng'? title:baslik}  </p>
                                        <span className={classes.dateNews}> {date} </span> <p className={classes.textNews}>{this.state.language==='eng'? text:metin}</p>
                                        <button  onClick={()=> window.open(source)} className={[classes.headerButton,classes.buttonNews].join(' ')}>{this.state.language==='eng'? 'Read More':'Daha Fazla Bilgi'}</button>
                                    </div>
                                    
                                </div>
                            );
                            })}

                            </Slider> 
                    <div className={classes.arrowDivNews}>
                       <button  onClick={this.previous} className={[classes.left,classes.newsArrows].join(' ')}> </button>
                       <button  onClick={this.next} className={classes.newsArrows}> </button>
                    </div>
                    <span className={classes.span}></span>
                </div>      
        );
        }else{
            return (
            <div className={classes.NewsDiv}>
                <link
                    rel="stylesheet"
                    type="text/css"
                    charSet="UTF-8"
                    href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
                />
                <link
                    rel="stylesheet"
                    type="text/css"
                    href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
                />
                <div className={classes.NewsTitle}>
                <p className={[classes.partnersText,classes.partnersTextbold,classes.newsText].join(' ')}>{this.state.language==='eng'? 'News':'Haberlerimiz '} </p>
                </div>
                <Slider ref={c => (this.slider = c)}  {...settings}>
                    {this.state.news.map((user, index) => {
                    const { _id, text,title,image, date, source,baslik,metin} = user 
                    return (
                        <div key={_id}>
                            <img alt="img" className={classes.imageNews} src={`../..${image }` }/>
                            <div className={[classes.NewsT,classes.newsT].join(' ')}>
                                <p className={classes.titleNews}>{this.state.language==='eng'? title:baslik}   </p>
                                 <p className={classes.textNews}><span className={classes.dateNews}> {date} </span>{this.state.language==='eng'? text:metin} </p>
                                <button style={{display: source? 'block': 'none'}} onClick={()=> window.open(source)} className={[classes.headerButton,classes.buttonNews].join(' ')}>{this.state.language==='eng'? 'Read More':'Daha Fazla Bilgi'}</button>
                            </div>
                            
                        </div>
                    );
                    })}
                </Slider>
                <div className={classes.arrowDivNews}>
                   <button  onClick={this.previous} className={[classes.left,classes.newsArrows].join(' ')}> </button>
                   <button  onClick={this.next} className={classes.newsArrows}> </button>
                </div>
                <span className={classes.span}></span>
            </div>      
    );
        }
        
    }
}


export default News;