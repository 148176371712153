import axios from 'axios' 
const api = axios.create({
    baseURL: 'https://web.sempertech.com.tr/apiadmin',
mode : 'cors',
    headers : {'Access-Control-Allow-Origin':'*'}
})
const apiProduct = axios.create({
    baseURL: 'https://web.sempertech.com.tr/apiproduct',
mode : 'cors',
    headers : {'Access-Control-Allow-Origin':'*'}
})
const apiPeople = axios.create({
    baseURL: 'https://web.sempertech.com.tr/apipeople',
mode : 'cors',
    headers : {'Access-Control-Allow-Origin':'*'}
})
const apiNews = axios.create({
    baseURL: 'https://web.sempertech.com.tr/apinews',
    mode : 'cors',
    headers : {'Access-Control-Allow-Origin':'*'}
})
const apiClients = axios.create({
    baseURL: 'https://web.sempertech.com.tr/apiclients',
mode : 'cors',
    headers : {'Access-Control-Allow-Origin':'*'}
})

export const loginAdminUser = payload =>  api.put(`/adminUsers`,payload )
export const verifyAdminUser = payload => api.get(`/adminUser`,payload )
export const insertAdminUser = payload => api.post(`/adminUser`, payload)
export const getAllAdminUsers = () => api.get(`/adminUsers`)
export const updateAdminUserById = (id, payload) => api.put(`/adminUser/${id}`, payload)
export const deleteAdminUserById = id => api.delete(`/adminUser/${id}`)
export const getAdminUserById = id => api.get(`/adminUser/${id}`)

export const insertProducts = payload => apiProduct.post(`/products`, payload)
export const getAllProducts = () => apiProduct.get(`/products`)
export const updateProductsById = (id, payload) => apiProduct.put(`/products/${id}`, payload)
export const deleteProductsById = id => apiProduct.delete(`/products/${id}`)
export const getProductsById = id => apiProduct.get(`/products/${id}`)

export const insertPeople = payload => apiPeople.post(`/people`, payload)
export const getAllPeople = () => apiPeople.get(`/people`)
export const updatePeopleById = (id, payload) => apiPeople.put(`/people/${id}`, payload)
export const deletePeopleById = id => apiPeople.delete(`/people/${id}`)
export const getPeopleById = id => apiPeople.get(`/people/${id}`)

export const insertNews = payload => apiNews.post(`/news`, payload)
export const getAllNews = () => apiNews.get(`/news`)
export const updateNewsById = (id, payload) => apiNews.put(`/news/${id}`, payload)
export const deleteNewsById = id => apiNews.delete(`/news/${id}`)
export const getNewsById = id => apiNews.get(`/news/${id}`)

export const insertClients = payload => apiClients.post(`/clients`, payload)
export const getAllClients  = () => apiClients.get(`/clients`)
export const updateClientsById = (id, payload) => apiClients.put(`/clients/${id}`, payload)
export const deleteClientsById = id => apiClients.delete(`/clients/${id}`)
export const getClientsById = id => apiClients.get(`/clients/${id}`)

const apis = {
    insertProducts,
    getAllProducts,
    updateProductsById,
    deleteProductsById,
    getProductsById,
    insertAdminUser,
    getAllAdminUsers,
    updateAdminUserById,
    deleteAdminUserById,
    getAdminUserById,
    insertPeople,
    getAllPeople,
    updatePeopleById,
    deletePeopleById,
    getPeopleById,
    insertNews,
    getAllNews,
    updateNewsById,
    deleteNewsById,
    getNewsById,
    insertClients,
    getAllClients,
    updateClientsById,
    deleteClientsById,
    getClientsById,
    loginAdminUser,
    verifyAdminUser

}
export default apis
