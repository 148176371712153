import React, { Component } from 'react'
import apis from '../../api/index'
import classes from './AdminNews.module.css'
import rightArrow from '../../assets/images/rightArrow.png'
import leftArrow from '../../assets/images/leftArrow.png'
import imageHolder from '../../assets/images/imageHolder.png'
import DeleteNews from './DeleteNews' 
class NewsUpdate extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: this.props.id,
            title:'',
            text: '',
            image: [],
            date: '',
            source: '',
            baslik:'',
            metin:'',
            temp:0
        }
        this.onImgLoad = this.onImgLoad.bind(this)
    }
    handleChangeInputTitle = async event => {
        const title = event.target.value
        this.setState({title: title })
    }
    handleChangeInputBaslik = async event => {
        const baslik = event.target.value
        this.setState({baslik: baslik })
    }

    handleChangeInputMetin = async event => {
        const metin = event.target.value
        this.setState({metin: metin })
    }


    handleChangeInputText = async event => {
        const text = event.target.value
        this.setState({text: text })
    }

    handleReader=(e)=>{
        let str=e.target.result
        this.setState({
            image: this.state.image.concat(btoa(str))
          }) 
    }
    handleChangeInputImage (event) {
        const file = event.target.files[0]
        if(file){
            const reader=new FileReader()
            reader.onload=this.handleReader.bind(this)
            reader.readAsBinaryString(file)
            this.setState({temp:this.state.image.length})
        }

    }
    handleChangeInputDate = async event => {
        const date = event.target.value
        this.setState({ date })
    }

    handleChangeInputSource = async event => {
        const source = event.target.value
        this.setState({source })
    }


    handleUpdateNews = async () => {
        var v=false
        if(localStorage.getItem("auth")){
            await apis.verifyAdminUser({headers:{"x-auth-token":JSON.parse(localStorage.getItem("auth")).value,}}).then(res => {
                var object = JSON.parse(localStorage.getItem("auth")),
                dateString = object.timestamp,
                now = new Date().getTime().toString();
                var msec=now-dateString 
                var minutes = Math.floor(msec / 60000); 
                if(minutes>30){
                    localStorage.removeItem("auth")
                    window.location.href='/admin'
                }else{
                    v=true
                }
            })
          }else{
            window.location.href='/admin' 
          }
          if(v){
            const { id, text, image, date, source, title,baslik,metin } = this.state
            const payload = { text,image, date, source,title,baslik,metin}

            await apis.updateNewsById(id, payload).then(res => {
                window.alert(`News updated successfully`)
                this.setState({
                    title:'',
                    text: '',
                    image: '',
                    date: '',
                    source: '',

                })
            })
            window.location.href = `/admin/news`
        }
    }
    prev=()=>{
        let r=this.state.temp
        if( r>0){
            this.setState({temp:r-1})
        }
    }
    next=()=>{
        let r=this.state.temp
        if(this.state.image.length > r+1){
            this.setState({temp:r+1})
        }
    }
    deleteImage=()=>{
        const temp=this.state.temp
        let tempImg =this.state.image
        tempImg.splice(temp, 1); 
        this.setState({image:tempImg,
         temp:temp!==0? temp-1: temp})
    }
    componentDidMount = async () => {
        const { id } = this.state
        const news = await apis.getNewsById(id)

        this.setState({
            title: news.data.data.title,
            text: news.data.data.text,
            image: news.data.data.image,
            date: news.data.data.date,
            source: news.data.data.source,
            baslik: news.data.data.baslik,
            metin: news.data.data.metin,
        })
    } 
    
    onImgLoad({target:img}) {
        let width=img.naturalWidth 
        let height =img.naturalHeight  
        let ratio=width/height 
        if(!(ratio<=1.8 && ratio>=1)){
            window.alert(`Logo must be width: 40x and height:27x`)                   
            const temp=this.state.temp
            let tempImg =this.state.image
            tempImg.splice(temp, 1); 
            this.setState({image:tempImg,
            temp:temp!==0? temp-1: temp})
        }
    }
    render() { 
        const { id,text,title,image, date, source,baslik,metin} = this.state  
        return (
            <div className={classes.updateDiv}> 
            <p className={classes.createTitle2}>UPDATE NEWS</p>
            <div className={classes.div1}>
                <label  className={classes.label}>Photo </label>
                <img alt="<" className={classes.left} onClick={this.prev} src={leftArrow}/>
                <p onClick={this.deleteImage} className={classes.imgX}>&#10006;</p>
                <img alt="img" onLoad={this.onImgLoad}  className={[classes.logo,classes.multiImg].join(' ')} src={image.length>this.state.temp? image[this.state.temp].substr(0,7)==='/images'? `../..${image[this.state.temp]}` : 'data:image/jpeg;base64,'+image[this.state.temp]:imageHolder }/> 
                <img alt=">" onClick={this.next} className={classes.right} src={rightArrow}/>
                
                <input
                    className={classes.dosyasec}
                    type="file"
                    onChange={(event) => this.handleChangeInputImage(event)}
                />
                <label className={classes.upload}> UPLOAD</label>  
                <div  className={classes.dateDiv}>
                    <label  className={classes.label}>Date</label>
                    <input
                        className={classes.input}
                        type="date"
                        value={date}
                        onChange={this.handleChangeInputDate}
                    />
                </div> 
                <div className={classes.sourceDiv}>
                    <label  className={classes.label}>Source</label>
                    <input
                        className={classes.input}
                        type="text"
                        value={source}
                        onChange={this.handleChangeInputSource}
                    />
                </div>   
                <label className={classes.label}>Title </label>
                <input
                    className={[classes.input,classes.titleIn].join(' ')}
                    type="text"
                    value={title}
                    onChange={this.handleChangeInputTitle}
                />     
              <label className={classes.label}>Başlık </label>
                    <input
                        className={[classes.input,classes.titleIn].join(' ')}
                        type="text"
                        value={baslik}
                        onChange={this.handleChangeInputBaslik}
                    />     
                    
            </div>
            <div className={classes.div2}>
            <label  className={classes.label}>Text </label>
            <textarea rows="5" cols="40"
             className={[classes.inputT, classes.input].join(' ')}
                type="text"
                value={text}
                onChange={this.handleChangeInputText}
            />  
            <label  className={classes.label}>Metin </label>
                <textarea rows="5" cols="40"
                 className={[classes.inputT, classes.input].join(' ')}
                    type="text"
                    value={metin}
                    onChange={this.handleChangeInputMetin}
                />  
            </div>
            <DeleteNews id={id} update={true}/>
                <button className={classes.createButton} onClick={this.handleUpdateNews}>UPDATE NEWS</button>
       </div>
        )
    }
}

export default NewsUpdate
