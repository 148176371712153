import React, { Component } from 'react'
import apis from '../../api/index'
import classes from './AdminUsers.module.css'
import DeleteAdminUser from './DeleteAdminUser'

class AdminUsersUpdate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: this.props.id,
            username: '',
            password: '',
        }
    }

    handleChangeInputName = async event => {
        const username = event.target.value
        this.setState({username: username })
    }

    handleChangeInputPassword = async event => {
        const password = event.target.value
        this.setState({password: password })
    }


    handleUpdateAdminUser = async () => {
        var v=false
        if(localStorage.getItem("auth")){
            await apis.verifyAdminUser({headers:{"x-auth-token":JSON.parse(localStorage.getItem("auth")).value,}}).then(res => {
                var object = JSON.parse(localStorage.getItem("auth")),
                dateString = object.timestamp,
                now = new Date().getTime().toString();
                var msec=now-dateString 
                var minutes = Math.floor(msec / 60000); 
                if(minutes>30){
                    localStorage.removeItem("auth")
                    window.location.href='/admin'
                }else{
                    v=true
                }
            })
          }else{
            window.location.href='/admin' 
          }
          if(v){
              const { id, username, password } = this.state
            const payload = { username, password}

            await apis.updateAdminUserById(id, payload).then(res => {
                window.alert(`Admin updated successfully`)
                this.setState({
                    username: '',
                    password: '',
                })
            })
            window.location.href = `/admin/adminUsers`
          }
        
    }

    componentDidMount = async () => {
        const { id } = this.state
        const adminUser = await apis.getAdminUserById(id)

        this.setState({
            username: adminUser.data.data.username,
            password: adminUser.data.data.password,
        })
    }
    handleFocus = (event) => event.target.select();
    render() { 
        const {id, username, password} = this.state
        return (
            <div className={classes.updateDiv}> 
                 <p className={classes.createTitle2}>UPDATE ADMIN</p>
                <div className={classes.inputDiv}>
                    <label className={classes.label}>User Name </label>
                <input
                    className={classes.input}
                    type="text"
                    value={username}
                    onChange={this.handleChangeInputName}
                />

                </div>
                <div className={classes.inputDiv}>
                    <label className={classes.label}>Password </label>
                <input
                    className={classes.input}
                    onClick={this.handleFocus}
                    type="password"
                    value={password}
                    onChange={this.handleChangeInputPassword}
                />
                <p className={classes.minText}>Minimum 6 characters</p>
                </div>
                <DeleteAdminUser id={id} uodate={true}/>
                
                <button className={classes.createButton} onClick={this.handleUpdateAdminUser}>UPDATE ADMIN</button>
                
            </div>
        )
    }
}

export default AdminUsersUpdate
