import React, { Component } from 'react'
import api from '../../api/index'
import classes from './AdminUsers.module.css'

class AdminUsersInsert extends Component {
    constructor(props) {
        super(props)

        this.state = {
            username: '',
            password: '',
        }
    }

    handleChangeInputName = async event => {
        const username = event.target.value
        this.setState({ username })
    }

    handleChangeInputPassword = async event => {
        const password = event.target.value
        this.setState({ password })
    }



    handleIncludeAdminUser = async () => {
        var v=false
        if(localStorage.getItem("auth")){
            await api.verifyAdminUser({headers:{"x-auth-token":JSON.parse(localStorage.getItem("auth")).value,}}).then(res => {
                var object = JSON.parse(localStorage.getItem("auth")),
                dateString = object.timestamp,
                now = new Date().getTime().toString();
                var msec=now-dateString 
                var minutes = Math.floor(msec / 60000); 
                if(minutes>30){
                    localStorage.removeItem("auth")
                    window.location.href='/admin'
                }else{
                    v=true
                }
            })
          }else{
            window.location.href='/admin' 
          }
          if(v){
            const { username, password } = this.state
            const payload = { username, password}

            await api.insertAdminUser(payload).then(res => {
                window.alert(`Admin inserted successfully`)
                this.setState({
                    username: '',
                    password: '',
                })
            })
            window.location.href = `/admin/adminUsers`
          }
        
        
    }

    render() {
        const { username, password } = this.state
        return (
            <div className={classes.createDiv}> 
                <p className={classes.createTitle2}>NEW ADMIN</p>
                <div className={classes.inputDiv}>
                    <label className={classes.label}>User Name </label>
                <input
                    className={classes.input}
                    type="text"
                    value={username}
                    onChange={this.handleChangeInputName}
                />

                </div>
                <div className={classes.inputDiv}>
                    <label className={classes.label}>Password </label>
                <input
                    className={classes.input}
                    type="password"
                    value={password}
                    onChange={this.handleChangeInputPassword}
                />
                <p className={classes.minText}>Minimum 6 characters</p>
                </div>
                
                <button className={classes.createButton} onClick={this.handleIncludeAdminUser}>CREATE</button>
            </div>
        )
    }
}

export default AdminUsersInsert