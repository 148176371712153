import React, { Component } from 'react'
import api from '../../api/index'
import classes from './AdminProducts.module.css'
import logoHolder from '../../assets/images/logoHolder.png'
import imageHolder from '../../assets/images/imageHolder.png' 

class ProductsInsert extends Component {
    constructor(props) {
        super(props)

        this.state = {
            name: '',
            text: '',
            logo:'', 
            status: 'cyber',
            link: '',
            HImage:'', 
            hakkinda:'',
            isim:'',
            thisLogo:logoHolder,
            thisHImage:imageHolder,
            
        } 
        this.onImgLoad = this.onImgLoad.bind(this)  
    }

    handleChangeInputName = async event => {
        const name = event.target.value
        this.setState({ name })
    }

    handleChangeInputText = async event => {
        const text = event.target.value
        this.setState({ text })
    }
    handleReaderLogo=(e)=>{
        let str=e.target.result
        this.setState({logo:btoa(str)})
        this.setState({thisLogo:'data:image/jpeg;base64,'+btoa(str)})

    }
    handleChangeInputLogo (event) { 
        const file = event.target.files[0]
        if(file){
            const reader=new FileReader()
            reader.onload=this.handleReaderLogo.bind(this)
            reader.readAsBinaryString(file)
        }
    }
    handleReaderHImage=(e)=>{
        let str=e.target.result
        this.setState({HImage:btoa(str)})
        this.setState({thisHImage:'data:image/jpeg;base64,'+btoa(str)})

    }
    handleChangeInputHImage (event) {
        const file = event.target.files[0] 
        if(file){
            const reader=new FileReader()
            reader.onload=this.handleReaderHImage.bind(this)
            reader.readAsBinaryString(file)
        }
    }
  
    handleChangeInputStatus = async event => {
        const status = event.target.value 
        this.setState({ status })
    }
    handleChangeInputHakkinda = async event => {
        const hakkinda = event.target.value
        this.setState({ hakkinda })
    }
    handleChangeInputIsim = async event => {
        const isim = event.target.value
        this.setState({ isim })
    }
    handleChangeInputLink = async event => {
        const link = event.target.value
        this.setState({ link })
    }
    onImgLoad({target:img}) {
        let width=img.naturalWidth 
        let height =img.naturalHeight  
        let alt= img.alt
        let ratio=width/height 
        let photo=!(ratio<=3.6 && ratio>=3.4) 
        if (alt==="logo"){ 
            if(ratio!==1){
                if( photo ){
                     window.alert(`Logo must be width: 7x and height:1x`)                   
                this.setState({logo:'',thisLogo:logoHolder})
                }
               
            }
        }else if (alt==="HImage"){
            if(!(ratio<=1.5 && ratio>=0.8)){
                window.alert(`Homepage Image must be width: 9x and height:8x`)                   
                this.setState({HImage:'',thisHImage:imageHolder})
            } 
       }
      
    }
    handleIncludeProducts = async () => {
        var v=false
        if(localStorage.getItem("auth")){
            await api.verifyAdminUser({headers:{"x-auth-token":JSON.parse(localStorage.getItem("auth")).value,}}).then(res => {
                var object = JSON.parse(localStorage.getItem("auth")),
                dateString = object.timestamp,
                now = new Date().getTime().toString();
                var msec=now-dateString 
                var minutes = Math.floor(msec / 60000); 
                if(minutes>30){
                    localStorage.removeItem("auth")
                    window.location.href='/admin'
                }else{
                    v=true
                }
            })
          }else{
            window.location.href='/admin' 
          }
          if(v){
            const { name,text, status,logo ,HImage,link,hakkinda,isim} = this.state

            const payload = { name,text, status,logo,link,HImage,hakkinda,isim}

            await api.insertProducts(payload).then(res => {
                window.alert(`Product inserted successfully`)
                this.setState({
                    name: '',
                    text: '', 
                    status: 'cyber',
                    logo:'',
                    link:'',
                    HImage:'',
                    hakkinda:'',
                    isim:'',
                })
            })
            window.location.href = `/admin/products`
        }
    }


    render() { 
        const { name,text,status,link,isim,hakkinda } = this.state
        return (
            <div className={classes.createDiv}>
                <p className={classes.createTitle2}>NEW PRODUCT</p>
                <div className={classes.div1}>
                    <label  className={classes.label}>Logo</label>
                
                    <div className={classes.div3}>
                        <img alt="logo" onLoad={this.onImgLoad}  className={classes.logo} src={this.state.thisLogo}/>
                        <input
                            className={classes.dosyasec}
                            type="file"
                            onChange={(event) => this.handleChangeInputLogo(event)}
                        />
                    <label className={classes.upload}> UPLOAD</label>
                </div>
                <label  className={classes.label}>Name </label>
                <input
                    className={classes.input}
                    type="text"
                    value={name}
                    onChange={this.handleChangeInputName}
                />
                 <label  className={classes.label}>About </label>
                <textarea
                 className={[classes.inputT, classes.input].join(' ')}
                    type="text"
                    value={text}
                    onChange={this.handleChangeInputText}
                />  
                </div>
                <div className={classes.div11}>
                <label  className={classes.label}>Homepage Image</label>
                <div className={classes.div3}>
                    <img alt="HImage"   onLoad={this.onImgLoad} className={classes.logo} src={this.state.thisHImage}/>
                    <input
                        className={classes.dosyasec}
                        type="file"
                      
                        onChange={(event) => this.handleChangeInputHImage(event)}
                    />
                     <label className={classes.upload}> UPLOAD</label>
                      <label  className={classes.label}>İsim </label>
                <input
                    className={classes.input}
                    type="text"
                    value={isim}
                    onChange={this.handleChangeInputIsim}
                />
                   
                </div>
               
                  
                </div>
              <div className={classes.div1} >   
      
                   <label className={classes.label}>Link </label>
                <input
                    className={classes.input}
                    type="text"
                    value={link}
                    onChange={this.handleChangeInputLink}
                />
              
                <label  className={classes.label}>Hakkında </label>
                <textarea
                 className={[classes.inputT, classes.input].join(' ')}
                    type="text"
                    value={hakkinda}
                    onChange={this.handleChangeInputHakkinda}
                />  
                 <label  className={classes.label}  >Status </label>
                <select
                    className={[classes.input, classes.select].join(' ')}
                    type="text"
                    value={status}
                    onChange={this.handleChangeInputStatus}> 
                        <option value="cyber">Cyber Security</option>
                        <option value="big">Big Data & Data Analytics</option>
                        <option value="future">Future Mobile Networks</option>
                        <option value="smart">Smart Buildings & IoT</option>
                </select>
               
                </div>
                <div className={classes.div11}>

               

                <button className={classes.createButton} onClick={this.handleIncludeProducts}>CREATE PRODUCT</button>
                </div>
           
               
               
               
            </div>
        )
    }
}

export default ProductsInsert