import React, { Component } from 'react'
import api from '../../api/index'
import classes from './AdminNews.module.css'
import imageHolder from '../../assets/images/imageHolder.png' 
import rightArrow from '../../assets/images/rightArrow.png'
import leftArrow from '../../assets/images/leftArrow.png'

class NewsInsert extends Component {
    constructor(props) {
        super(props)

        this.state = {
            title:'',
            text: '',
            image: [],
            date: '',
            thisImage: imageHolder,
            source: '',
            baslik:'',
            metin:'',
            imageAt:-1,
            imageDiv:(<div>
                <img alt="img" onLoad={this.onImgLoad} className={classes.logo} src={imageHolder}/> <input
            className={classes.dosyasec}
                type="file"
                onChange={(event) => this.handleChangeInputImage(event)}
            />
                </div>)
        }
        this.handleChangeInputImage = this.handleChangeInputImage.bind(this)
        this.onImgLoad = this.onImgLoad.bind(this)
    }
    handleChangeInputTitle = async event => {
        const title = event.target.value
        this.setState({title: title })
    }
    handleChangeInputBaslik = async event => {
        const baslik = event.target.value
        this.setState({baslik: baslik })
    }

    handleChangeInputMetin = async event => {
        const metin = event.target.value
        this.setState({metin: metin })
    }


    handleChangeInputText = async event => {
        const text = event.target.value
        this.setState({text: text })
    }
    handleReader=(e)=>{
        let str=e.target.result
        let at = this.state.image.length+1 
        this.setState({
            image: this.state.image.concat(btoa(str)),
            thisImage:'data:image/jpeg;base64,'+btoa(str),
            imageDiv:(<div>
                <img alt="<" className={classes.left} onClick={this.prev} src={leftArrow}/>
                <p  onClick={this.deleteImage} className={classes.imgX}>&#10006;</p>
                <img alt="img" onLoad={this.onImgLoad} className={[classes.logo,classes.multiImg].join(' ')} src={'data:image/jpeg;base64,'+btoa(str)}/>
                <img alt=">"onClick={this.next} className={classes.right} src={rightArrow}/>
                
                <input
                    className={classes.dosyasec}
                    type="file"
                    onChange={(event) => this.handleChangeInputImage(event)}
                />
            </div>),
            imageAt:at
          })
    }
    prev=()=>{
        const temp=this.state.imageAt
       
        if(temp>0){  
            this.setState({imageDiv:(<div>
                <img alt="<" className={classes.left} onClick={this.prev} src={leftArrow}/>
                <p onClick={this.deleteImage} className={classes.imgX}>&#10006;</p>
                <img alt="img" onLoad={this.onImgLoad} className={[classes.logo,classes.multiImg].join(' ')} src={'data:image/jpeg;base64,'+ this.state.image[temp-1]}/>
                <img alt=">" onClick={this.next} className={classes.right} src={rightArrow}/>
                
                <input
                    className={classes.dosyasec}
                    type="file"
                    onChange={(event) => this.handleChangeInputImage(event)}
                />
            </div>)})
            this.setState({imageAt:temp-1})
        }
    }
    next=()=>{
        const temp=this.state.imageAt
        
        if(this.state.image.length > temp+1){ 
            this.setState({imageDiv:(<div>
                <img alt="<" className={classes.left} onClick={this.prev} src={leftArrow}/>
                <p onClick={this.deleteImage} className={classes.imgX}>&#10006;</p>
                <img alt="img" onLoad={this.onImgLoad} className={[classes.logo,classes.multiImg].join(' ')} src={'data:image/jpeg;base64,'+ this.state.image[temp+1]}/>
                <img alt=">" onClick={this.next} className={classes.right} src={rightArrow}/>
                
                <input
                    className={classes.dosyasec}
                    type="file"
                    onChange={(event) => this.handleChangeInputImage(event)}
                />
            </div>),
            imageAt:temp+1,
            })

        }
        
    }
    onImgLoad({target:img}) {
        let width=img.naturalWidth 
        let height =img.naturalHeight  
        let ratio=width/height 
        if(!(ratio<=1.8 && ratio>=1)){
            window.alert(`Logo must be width: 40x and height:27x`)                   
            const temp=this.state.imageAt
            let tempImg =this.state.image
            tempImg.splice(temp-1, 1); 
            this.setState({image:tempImg,
            imageDiv:(<div>
                <img alt="<" className={classes.left} onClick={this.prev} src={leftArrow}/>
                <p onClick={this.deleteImage} className={classes.imgX}>&#10006;</p>
                <img onLoad={this.onImgLoad} alt="img" className={[classes.logo,classes.multiImg].join(' ')} src={this.state.image.length>0? 'data:image/jpeg;base64,'+ this.state.image[temp-2]:imageHolder}/>
                <img alt=">" onClick={this.next} className={classes.right} src={rightArrow}/>
                
                <input
                    className={classes.dosyasec}
                    type="file"
                    onChange={(event) => this.handleChangeInputImage(event)}
                />
            </div>), imageAt:temp-1})
        }
    }
    deleteImage=(e)=>{ 
        const temp=this.state.imageAt
        let tempImg =this.state.image
        tempImg.splice(temp-1, 1); 
        this.setState({image:tempImg,
            imageDiv:(<div>
                <img alt="<" className={classes.left} onClick={this.prev} src={leftArrow}/>
                <p onClick={this.deleteImage} className={classes.imgX}>&#10006;</p>
                <img onLoad={this.onImgLoad} alt="img" className={[classes.logo,classes.multiImg].join(' ')} src={this.state.image.length>0? 'data:image/jpeg;base64,'+ this.state.image[temp-2]:imageHolder}/>
                <img alt=">" onClick={this.next} className={classes.right} src={rightArrow}/>
                
                <input
                    className={classes.dosyasec}
                    type="file"
                    onChange={(event) => this.handleChangeInputImage(event)}
                />
            </div>), imageAt:temp-1})
    }
    handleChangeInputImage = async event =>{
        const file = event.target.files[0] 
        if(file){
            const reader=new FileReader()
            reader.onload=this.handleReader.bind(this)
            reader.readAsBinaryString(file)
            let at=this.state.image 
            this.setState({imageAt:at.length}) 
        }

    }
    handleChangeInputDate = async event => {
        const date = event.target.value
        this.setState({date: date })
    }

    handleChangeInputSource = async event => {
        const source = event.target.value
        this.setState({source: source })
    }

    handleIncludeNews = async () => {
        var v=false
        if(localStorage.getItem("auth")){
            await api.verifyAdminUser({headers:{"x-auth-token":JSON.parse(localStorage.getItem("auth")).value,}}).then(res => {
                var object = JSON.parse(localStorage.getItem("auth")),
                dateString = object.timestamp,
                now = new Date().getTime().toString();
                var msec=now-dateString 
                var minutes = Math.floor(msec / 60000); 
                if(minutes>30){
                    localStorage.removeItem("auth")
                    window.location.href='/admin'
                }else{
                    v=true
                }
            })
          }else{
            window.location.href='/admin' 
          }
          if(v){
            const { text, image, date, source, title,baslik,metin  } = this.state
            const payload = { text,image, date, source,title,baslik,metin }
    
            await api.insertNews(payload).then(res => {
                window.alert(`News inserted successfully`)
                this.setState({
                    title:'',
                    text: '',
                    image: [],
                    date: '',
                    source: '',
                })
            })
            window.location.href = `/admin/news`
        }
    }

    render() {
        const {  text,title,  date, source,baslik,metin } = this.state
        let d=(this.state.imageDiv)
        return (
            <div className={classes.createDiv}> 
                <p className={classes.createTitle2}>NEW NEWS</p>
                <div className={classes.div1}>
                    <label  className={classes.label}>Photo </label>
                   {d}
                    <label className={classes.upload}> UPLOAD</label>
                    <div  className={classes.dateDiv}>
                        <label  className={classes.label}>Date</label>
                        <input
                            className={classes.input}
                            type="date"
                            value={date}
                            onChange={this.handleChangeInputDate}
                        />
                    </div> 
                    <div className={classes.sourceDiv}>
                        <label  className={classes.label}>Source</label>
                        <input
                            className={classes.input}
                            type="text"
                            value={source}
                            onChange={this.handleChangeInputSource}
                        />
                    </div>   
                    <label className={classes.label}>Title </label>
                    <input
                        className={[classes.input,classes.titleIn].join(' ')}
                        type="text"
                        value={title}
                        onChange={this.handleChangeInputTitle}
                    />     
                     <label className={classes.label}>Başlık </label>
                    <input
                        className={[classes.input,classes.titleIn].join(' ')}
                        type="text"
                        value={baslik}
                        onChange={this.handleChangeInputBaslik}
                    />     
                    
                </div>
                <div className={classes.div2}>
                <label  className={classes.label}>Text </label>
                <textarea rows="5" cols="40"
                 className={[classes.inputT, classes.input].join(' ')}
                    type="text"
                    value={text}
                    onChange={this.handleChangeInputText}
                />  
                 <label  className={classes.label}>Metin </label>
                <textarea rows="5" cols="40"
                 className={[classes.inputT, classes.input].join(' ')}
                    type="text"
                    value={metin}
                    onChange={this.handleChangeInputMetin}
                />  
                </div>
            
                <button className={classes.createButton} onClick={this.handleIncludeNews}>CREATE NEWS</button>
         
            </div>
        )
    }
}

export default NewsInsert