import React, {Component} from 'react'
import classes from './Home.module.css'
import apis from '../../api/index'
import Slider from "react-slick"; 

class Partners extends Component  {
    constructor(props) {
        super(props);
        this.state={
        clients:[],
        language:'eng'
        }
       
      } 
    componentDidMount(){
        apis.getAllClients().then(a => {
            this.setState({
                clients: a.data.data,
            }) 
        })
       }
  render() {
        if(localStorage.getItem('language')!==this.state.language){
         this.setState({
             language:localStorage.getItem('language')
         })
        }
      const settings = {
    dots: false,
    infinite: true,
    speed: 2000, 
    autoplaySpeed:4000,
    slidesToShow: this.props.isMobile? 1: this.props.isTablet? 4:5,
    autoplay:true,
    swipeToSlide: true,
    variableWidth: this.props.isMobile? true:false,
    centerMode: this.props.isMobile? true:false,
};
   
    return (
        <div className={classes.partnersDiv}>
            <div className={classes.titlePartners}>
                 <p className={[classes.partnersText,classes.partnersTextbold].join(' ')}>{this.state.language==='eng'? 'Our':'Ortaklarımız'}</p>
                <p  className={classes.partnersText}>{this.state.language==='eng'? 'Partners':''}</p>
            </div>
            <div className={classes.logos}>
            <Slider {...settings}>
            {this.state.clients? this.state.clients.map((user, index) => {
            const { _id, logoBW} = user 
              return (
                <div className={classes.partnerDiv} key={_id}>
                      <img className={classes.clientsLogo} src={ `../..${logoBW}`} alt="logo"/>
                </div>
              )
            }):<div></div>}
            </Slider>
            </div>
           
        </div>      
    );
    }
}


export default Partners;