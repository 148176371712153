import React from 'react';  
import Login from './Login' 

const AdminLogin =(props)=> {
 
    
    return ( 
        <div >
                <Login/>
                
        </div>
    );
}


export default AdminLogin;