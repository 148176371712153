import React, {Component} from 'react';
import classes from './About.module.css'
import Slider from "react-slick"; 
import apis from '../../api/index'
import Footer from '../HomePage/Footer'
import Modal from 'react-modal';
 
import about1 from '../../assets/images/about1.jpg'
import about2 from '../../assets/images/about2.jpg'
import about3 from '../../assets/images/about3.jpg'
import about4 from '../../assets/images/about4.jpeg'
import about5 from '../../assets/images/about5.jpg'

class About extends Component  {
  constructor(props) {
    super(props);
    this.state={
      founder:'',
      thisPerson:'',
      modalIsOpen:false,
      peopleSorted:[],
      people:[],
      originsEng:`Semper-Tech is a spin-off of DAI-Labor, which is a trend-setting research and innovations lab in Germany.\n \nSemper-Tech was founded by Prof. Albayrak. Prof. Albayrak is also the founder of German-Turkish Advanced Research Center for ICT (GT-ARC) and Deutsche Telekom Innovation Laboratories.\n\nUnder Prof. Albayrak’s leadership, Semper-Tech has been working to create a local ecosystem that fosters continuous and sustainable innovation with the help of global partners including DAI-Labor and GT-ARC.`,
      originsTr:'Semper-Tech, Almanya\'da trend belirleyen bir araştırma ve yenilik laboratuarı olan DAI-Labor\'un bir parçasıdır.\n\nSemper-Tech Prof. Albayrak tarafından kuruldu. Prof. Albayrak, aynı zamanda Alman-Türk Gelişmiş Araştırma Merkezi (GT-ARC) ve Deutsche Telekom İnovasyon Laboratuvarları\'nın kurucularından biridir.\n\nProf. Albayrak\'ın liderliği altında Semper-Tech, DAI-Labor ve GT-ARC gibi küresel ortakların yardımıyla sürekli ve sürdürülebilir yeniliği teşvik eden yerel bir ekosistem yaratmaya çalışıyor.',
      lifeEng:'As a result of horizontal organization, co-working relationships are strengthened with social bonds.\n \nTo its team, Semper-Tech offers a next generation comfort rather than a traditional colleagueship. With a fully digitalized office a smart space is created using sensors and control units around the office. The digitalization offers assistance for everyone at the office for better lighting, higher air quality and secures the entire office, while still saving energy and keeping it a "green" office.\n \nThe working environment is also automatically controlled for good O2 levels and better luminosity. This quality improves creativity and innovation for each and every colleague.',
      lifeTr:'Yatay organizasyonun bir sonucu olarak, sosyal paylaşımlarla birlikte çalışma ilişkileri güçlenir.\n\nSemper-Tech ekibine geleneksel bir meslektaşlık yerine bir sonraki nesil konfor sunuyor. Tamamıyla dijitalleştirilmiş bir ofiste sensörler ve kontrol üniteleri kullanılarak akıllı bir çalışma ortamı oluşturulur. Dijitalleştirme ile ofiste çalışan herkes için daha iyi aydınlatma, daha yüksek hava kalitesi sağlama ve ofisin tamamını güvence altına alma için yardım ederken, yine de enerji tasarrufu yaparak "yeşil" bir ofis kalmasını sağlıyor.\n\nÇalışma ortamı da otomatik olarak kontrol edilerek daha iyi oksijen seviyesi ve daha iyi bir aydınlatma sağlanıyor. Böylece ekipteki her bireyin yaratıcılık ve inovasyon değerleri gelişiyor.'
    }
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  } 
  
  componentDidMount() { 
    if( localStorage.getItem('location')!=='/about'){
      localStorage.setItem('location', '/about'); 
    }
    apis.getAllPeople().then(a => {
      this.setState({
          people: a.data.data ,
      })
      let people=a.data.data  
      let peopleSorted= people.sort((d, b) => (d.gridy === b.gridy?  d.gridx > b.gridx:d.gridy > b.gridy) ? 1 : -1)
      let founder= peopleSorted[0]
      peopleSorted.splice(0, 1)
      this.setState({
        peopleSorted ,founder
    }) 
         
  }) 
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }
  modalOpen(link,email, text,name,image, title,hakkinda,unvan){
    let thisPerson=[link,email, text,name,image, title,hakkinda,unvan]
    this.setState({thisPerson,
      modalIsOpen:true 
  })
  }
  CloseModal=()=>{ 
    this.setState({modalIsOpen:false})
  }
  render() {  
    var exactWidth = 1524
    document.body.style.zoom = document.documentElement.offsetWidth/exactWidth
    window.onresize = ()=>{
        document.body.style.zoom = document.documentElement.offsetWidth/exactWidth
    }  
    const settings = {
      dots: true,
      infinite: true,
      speed: 2000,
      slidesToShow: 1,
      autoplay:true,
      autoplaySpeed:6000,
      slidesToScroll: 1,
      pauseOnFocus:true, 
      variableWidth:true
  };
    return (
      <div style={{transform: this.props.sideBar?'translateX(-34%)':'' }} className={classes.Div} onClick={this.props.outsideHandle}>
        <div className={classes.head}></div>
        <div className={classes.titleDiv}>
          <p className={ classes.mainTitle }>{localStorage.getItem('language')==='eng'? 'About':'  '} </p><p className={[classes.mainTitle,classes.bold].join(' ')}>{localStorage.getItem('language')==='eng'? 'Us':'Hakkımızda'}</p>
        </div>
        <div className={classes.textDiv}>
           <div className={classes.line}></div> 
          <p className={classes.text}>{localStorage.getItem('language')==='eng'? 'The Passion to Develop Solutions for Society':'Tutkumuz, Toplum için Çözüm Geliştirmek'}</p>
        </div>
        <div className={classes.aboutDiv}>
           <img className={classes.img1} src={about1} alt="sahin" />
           <div className={classes.originsDiv}>
              <p className={classes.originsTitle}>{localStorage.getItem('language')==='eng'? 'Origins':' Köken'} </p>
              <p className={classes.originsText}>{localStorage.getItem('language')==='eng'? this.state.originsEng:this.state.originsTr} </p>
              <div className={classes.sliderDiv}>
                 <Slider ref={c => (this.slider = c)} {...settings}>
                  <img className={classes.sliderImg} src={about2} alt="slider"/>
                  <img className={classes.sliderImg} src={about3} alt="slider"/>
                  <img className={classes.sliderImg} src={about4} alt="slider"/>
                  <img className={classes.sliderImg} src={about5} alt="slider"/> 
              </Slider>
              <div className={classes.divArrows}>
                <button  onClick={this.previous} className={[classes.Arrows,classes.left].join(' ') }></button>
                <button  onClick={this.next} className={classes.Arrows}></button>
              </div> 
              <span className={classes.span}></span>
              </div>
              
           </div>
            <div className={classes.lifeDiv}>
              <p className={ [classes.bold2,classes.lifeTitle].join(' ') }>{localStorage.getItem('language')==='eng'? 'Life':'Semper-Tech\'de'} </p><p className={classes.lifeTitle} style={{marginRight:localStorage.getItem('language')==='eng'?'180px':'121px'}}>{localStorage.getItem('language')==='eng'? 'at Semper-Tech':'Yaşam'}</p>
              <p className={[classes.originsText,classes.lifeText].join(' ')}>{localStorage.getItem('language')==='eng'? this.state.lifeEng:this.state.lifeTr} </p>
            </div>
            <div className={classes.teamDiv}>
              <div className={classes.titleDiv2}>
                <p className={ [classes.bold2,classes.lifeTitle].join(' ') }>{localStorage.getItem('language')==='eng'? 'Our':'Takımımız  '} </p><p className={classes.lifeTitle}>{localStorage.getItem('language')==='eng'? 'Team':''}</p>
              </div>
              <div  >
                  <img alt="img" className={classes.founderImg} src={`../..${this.state.founder.image }`}/>
                  <p  className={classes.name}> {this.state.founder.name} </p>   
                  <p  className={classes.about}> {localStorage.getItem('language')==='eng'? this.state.founder.title:this.state.founder.unvan} </p>   
                  <div className={classes.socialDiv}> 
                    <p style={{display:this.state.founder.link? 'block': 'none' }}  onClick={()=>window.open(this.state.founder.link)}  className={[classes.bioLogo,classes.linkedin].join(' ')}>in</p>
                    <a style={{display:this.state.founder.email ?'block' : 'none' }} href={"mailto:"+this.state.founder.email} className={[classes.bioLogo,classes.email].join(' ')}> </a>
                    <p className={classes.bioLogo} onClick={()=>this.modalOpen( this.state.founder.link?this.state.founder.link:"" ,this.state.founder.email, this.state.founder.text,this.state.founder.name,this.state.founder.image, this.state.founder.title,this.state.founder.hakkinda,this.state.founder.unvan)}>BIO</p>
                  </div>
              </div>
              {this.state.peopleSorted.map((user, index) => {
            const { _id,link,email, text,name,image, title,hakkinda,unvan} = user
            return (
              <div className={classes.peopleDiv} key={_id}>
                  <img alt="img" className={classes.image} src={image?`../..${image }`: null}/>
                  <p  className={classes.name}> {name} </p>   
                  <p  className={classes.about}> {localStorage.getItem('language')==='eng'? title:unvan} </p>   
                  <div className={classes.socialDiv}> 
                    <p style={{display:link? 'block': 'none' }} onClick={()=>window.open(link )} className={[classes.bioLogo,classes.linkedin].join(' ')}>in</p>
                    <a style={{display:email ?'block' : 'none' }}    href={"mailto:"+ email} className={[classes.bioLogo,classes.email].join(' ')} > </a>
                    <p className={classes.bioLogo} onClick={()=>this.modalOpen( link?link:"",email, text,name,image, title,hakkinda,unvan)}>BIO</p>
                  </div>
                  
              </div>
          );
          })}
            </div>
        </div>
       
        <Footer isTablet={this.props.isTablet} isMobile={this.props.isMobile} />
        <Modal 
                    onRequestClose={this.CloseModal}
                    className={classes.Modal}
                    isOpen={this.state.modalIsOpen} 
                    contentLabel="Example Modal" 
                    ariaHideApp={false} 
                    > 
                    <div className={classes.modalDiv}>
                      <button className={classes.CloseButton} onClick={this.CloseModal}>&#10006;</button>
                      <div className={classes.gray}>
                        <img alt="img" className={classes.modalImg} src={ this.state.thisPerson[4]?`../..${this.state.thisPerson[4] }`: null} />
                      </div> 
                      <div className={classes.infoDiv}>
                        <p className={classes.infoName}>{this.state.thisPerson[3]}</p>
                        <p className={classes.infoTitle}>{localStorage.getItem('language')==='eng'? this.state.thisPerson[5]:this.state.thisPerson[7]}</p>
                        <div className={classes.modalsocialDiv}> 
                          <p style={{display:this.state.thisPerson? 'block':  'none'}} onClick={()=>window.open(this.state.thisPerson[0] )} className={[classes.bioLogo,classes.linkedin].join(' ')}>in</p>
                          <a style={{display:this.state.thisPerson[1] ?'block' : 'none' }} href={"mailto:"+this.state.thisPerson[1]} className={[classes.bioLogo,classes.email].join(' ')}> </a> 
                        </div>
                        <p className={classes.infoAbout}>{localStorage.getItem('language')==='eng'? this.state.thisPerson[2]:this.state.thisPerson[6]}</p>
                      </div> 
                   </div>
                  </Modal >
      </div>
    );
    }
}

export default About;
