import React, { Component } from 'react'
import apis from '../../api/index'
import classes from './AdminClients.module.css'
import logoHolder from '../../assets/images/logoHolder.png' 
import DeleteClients from './DeleteClients'

class ClientsUpdate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: this.props.id,
            name: '', 
            logoBW:'',
            link:'',
           
        }
        this.onImgLoad = this.onImgLoad.bind(this) 
    }

    handleChangeInputName = async event => {
        const name = event.target.value
        this.setState({ name })
    }
      handleReaderLogoBW=(e)=>{
        let str=e.target.result
        this.setState({logoBW:btoa(str)})
    }
   
    handleChangeInputLogoBW (event) {
        const file = event.target.files[0]
        if(file){
            const reader=new FileReader()
            reader.onload=this.handleReaderLogoBW.bind(this)
            reader.readAsBinaryString(file)
        }
    }
   
   
    handleChangeInputLink = async event => {
        const link = event.target.value
        this.setState({ link })
    }

    handleUpdateClients = async () => {
        var v=false
        if(localStorage.getItem("auth")){
            await apis.verifyAdminUser({headers:{"x-auth-token":JSON.parse(localStorage.getItem("auth")).value,}}).then(res => {
                var object = JSON.parse(localStorage.getItem("auth")),
                dateString = object.timestamp,
                now = new Date().getTime().toString();
                var msec=now-dateString 
                var minutes = Math.floor(msec / 60000); 
                if(minutes>30){
                    localStorage.removeItem("auth")
                    window.location.href='/admin'
                }else{
                    v=true
                }
            })
          }else{
            window.location.href='/admin' 
          } 
        if(v){
            const { id, name,link,  logoBW } = this.state
            const payload = { id, name,link,  logoBW  }

            await apis.updateClientsById(id, payload).then(res => {
                window.alert(`Client updated successfully`)
                this.setState({
                    name: '', 
                    logoBW:'',
                    link:'',
                
                })
            })
            window.location.href = `/admin/clients`
        }
    }
 
    componentDidMount = async () => {
        
        const { id } = this.state
        const clients = await apis.getClientsById(id)

        this.setState({
            name: clients.data.data.name, 
            logoBW: clients.data.data.logoBW,
            link: clients.data.data.link,
        })
    }
    onImgLoad({target:img}) {
        let width=img.naturalWidth 
        let height =img.naturalHeight  
        let alt= img.alt
        let ratio=width/height  
        if (alt==="logoBW"){ 
            if(ratio!==1){
                window.alert(`Logo must be a square`)                   
                this.setState({logoBW:''})
            }
        } 

    
      
    }

    render() {
        const { id,name,link,  logoBW } = this.state
        return (
            <div className={classes.divUpdate}>
                 <p className={classes.createTitle2}>UPDATE CLIENT </p> 
                    
                <div className={classes.div1}>
                    <label  className={classes.label}>Logo (Black&White) </label>
                
                    <div className={classes.div3}>
                        <img onLoad={this.onImgLoad}  alt="logoBW"className={classes.logo} src={logoBW!=='' ?logoBW.substr(0,7)==='/images'?`../..${logoBW}`: 'data:image/jpeg;base64,'+logoBW :logoHolder}/>
                        <input
                            className={classes.dosyasec}
                            type="file"
                            onChange={(event) => this.handleChangeInputLogoBW(event)}
                        />
                    <label className={classes.upload}> UPLOAD</label>
                </div>
                
                </div>
                <div className={classes.div11}>
                    <label  className={classes.label}>Name </label>
                    <input
                        className={classes.input}
                        type="text"
                        value={name}
                        onChange={this.handleChangeInputName}
                    />
                    <label className={classes.label}>Link </label>
                    <input
                        className={classes.input}
                        type="text"
                        value={link}
                        onChange={this.handleChangeInputLink}
                    />
                <DeleteClients id={id} update={true}/>
                <button className={classes.createButton}onClick={this.handleUpdateClients}>UPDATE PRODUCT</button>
              
                </div>
            </div>
        )
    }
}

export default ClientsUpdate
