import React, {Component} from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route 
} from "react-router-dom";
import classes from './SideBar.module.css';  
import AdminLogin from './../../Admin/AdminLogin'
  
 

class SideBar extends Component  {
   state={
      width: window.innerWidth,    
      scrolled:true,
      scrolledDown:false,
      scrolledMobile:false,
      language:'eng'
    }   

   
  render() {
 if(localStorage.getItem('language')!==this.state.language){
      this.setState({
          language:localStorage.getItem('language')
      })
     }
    return (
      <div  className={classes.sidebar}>
        <Router>
            <ul>
                <select value={localStorage.getItem('language')} onChange={this.props.HandleLanguage} className={classes.select} style={{color:this.state.scrolledDown?'#0081e9':'#ffffff'} } name="lang" id="lang">
                    <option value="eng">ENG </option>
                    <option value="tr">TR </option>
 
                </select>
                <button className={classes.sideText} onClick={()=>window.location.href = `/about `}>{this.state.language==='eng'? 'About Us': 'Hakkımızda'}</button>
                <button  className={classes.sideText} onClick={()=>window.location.href = `/focus `}>{this.state.language==='eng'? ' Our Focus':'Odaklarımız'}</button>
                <button  className={classes.sideText} onClick={()=>window.location.href = `/products `}>{this.state.language==='eng'? ' Products':'Ürünlerimiz'}</button>
                <button className={classes.sideText} onClick={()=>window.location.href = `/contact `}>{this.state.language==='eng'? ' Contact':'İletişim'}</button>
        
            </ul>
       
        <Switch>
          <Route exact path="/admin" component={AdminLogin} />
    
        </Switch> 
    </Router>
      </div>
    );
    }
}


export default SideBar;