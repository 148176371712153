import classes from './Admin.module.css';
import React, {Component} from 'react';
import apis from '../api/index'
import semper from '../assets/images/semper.svg'

class Login extends Component  {
  constructor(props) {
    super(props);
    this.state = {
        username: '',
        password: '',
        passwordColor:'#ffffff',
        usernameColor:'#ffffff'
    }
}

handleChangeInputName = async event => {
    const username = event.target.value
    this.setState({ username })
}

handleChangeInputPassword = async event => {
    const password = event.target.value
    this.setState({ password })
}

HandleLogin = async () => {
    const { username, password } = this.state
    const payload = { username, password}

    await apis.loginAdminUser(payload).then(res => {
        console.log(res.data.error)
        if(res.data.success===false){
            
            if(res.data.error==="all"){
                window.alert(`Can not logged in! `)
                this.setState({
                    usernameColor:'#f35037',
                    passwordColor:'#f35037',
            })
            }else if(res.data.error==="username"){
                window.alert(`Username doesn't exist! `)
                this.setState({
                    usernameColor:'#f35037',
                    passwordColor:'#ffffff',
            })
            }else if(res.data.error==="password"){ 
                window.alert(`Password must be at least 6 characters!`)
                this.setState({
                    usernameColor:'#0060ad',
                    passwordColor:'#f35037',
            })
            }

        }else{ 
            this.setState({
                username: '',
                password: '',
            })
            var object = {value: res.data.token, timestamp: new Date().getTime()}
            localStorage.setItem("auth", JSON.stringify(object));
            window.location.href = `/admin/adminUsers`
            
        }
        
    })
  
}
  componentDidMount= async ()=>{
      if(localStorage.getItem("auth")){
        await apis.verifyAdminUser({headers:{"x-auth-token":JSON.parse(localStorage.getItem("auth")).value,}}).then(res => {
            var object = JSON.parse(localStorage.getItem("auth")),
            dateString = object.timestamp,
            now = new Date().getTime().toString();
            var msec=now-dateString 
            var minutes = Math.floor(msec / 60000); 
            if(minutes<30){
                window.location.href = `/admin/adminUsers`
            }else{
                localStorage.removeItem("auth")
            }
        })
      }else{ 
      }
   
  }  

  render() {   
   
    return (
      <div className={classes.loginPage}>
          <div className={classes.loginDiv}> 
                <img alt="img" className={classes.sempImg} src={semper}/>
                <label className={classes.loginLabel}>Username </label>
                <input style={{borderColor:this.state.usernameColor}} onChange={this.handleChangeInputName} className={classes.in} type="text" />
                <label className={classes.loginLabel}>Password </label>
                <input style={{borderColor:this.state.passwordColor}} onChange={this.handleChangeInputPassword} className={classes.in} type="password" />
                <button onClick={this.HandleLogin} className={classes.button}>LOGIN</button>
          </div>
         
      </div>
     
    );
    }
}

export default Login;