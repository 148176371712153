// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SideBar_Sidediv__5mE2U{\n    background-color:#131d3b ;\n    width:20%;\n    height:30px\n}\n.SideBar_sidebar__UWUHS{\n    height: 100%;\n    width: 525px;\n    position: fixed;\n    z-index: 1200;\n    right: -100%;\n    top: 0;\n    background-color: #131d3b;\n    animation: SideBar_slide-open__1XjgH 0.8s forwards;\n}\n.SideBar_sidebar__UWUHS.SideBar_close__3b6ir {\n    right: -100%;\n}\n@keyframes SideBar_slide-open__1XjgH {\n    100% {right:0}\n}\n.SideBar_sideText__3EG8W{ \n    float: left;\n    font-family: Rubik-Medium;\n    margin-top: 165px;\n    margin-left: 75px;\n    outline: none;\n    font-size: 66px; \n    text-align: left;\n    color: #ffffff;\n    text-decoration: none;\n    border: none;\n    background: inherit;\n    cursor: pointer;\n}\n.SideBar_select__2QqMv{ \n    outline: none;\n    margin-top: 95px;\n    margin-left: 75px;\n    margin-bottom: 38px;\n    background: inherit;\n    border: none; \n    font-family: Rubik-Light;\n    font-size: 44px;\n    font-weight: 600;\n    letter-spacing: normal;\n    text-align: left;\n    width: 195px;\n  }\n  @media screen and (min-width: 415px) { \n      .SideBar_sideText__3EG8W{\n    margin-top: 144px;\n    font-size: 46px;\n  }\n  .SideBar_select__2QqMv{\n    width: 142px;\n    margin-left: 75px;\n    margin-bottom: 10px; \n    font-size: 41px;\n  }\n}", ""]);
// Exports
exports.locals = {
	"Sidediv": "SideBar_Sidediv__5mE2U",
	"sidebar": "SideBar_sidebar__UWUHS",
	"slide-open": "SideBar_slide-open__1XjgH",
	"close": "SideBar_close__3b6ir",
	"sideText": "SideBar_sideText__3EG8W",
	"select": "SideBar_select__2QqMv"
};
module.exports = exports;
