// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./assets/fonts/Rubik-Medium.ttf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./assets/fonts/Rubik-Light.ttf");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./assets/fonts/Rubik-Regular.ttf");
var ___CSS_LOADER_URL_IMPORT_3___ = require("./assets/fonts/Rubik-Bold.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
exports.push([module.id, "body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n \n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n@font-face {\n  font-family: 'Rubik-Medium';\n  src: local('Rubik-Medium'), url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('truetype');\n}\n@font-face {\n  font-family: 'Rubik-Light';\n  src: local('Rubik-Light'), url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('truetype');\n}\n@font-face {\n  font-family: 'Rubik-Regular';\n  src: local('Rubik-Regular'), url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('truetype');\n}\n@font-face {\n  font-family: 'Rubik-Bold';\n  src: local('Rubik-Bold'), url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format('truetype');\n}\n", ""]);
// Exports
module.exports = exports;
