import React, {Component} from 'react';
import Footer from '../HomePage/Footer'
import classes from './Contact.module.css'

class Policy extends Component  {

constructor(props) {
        super(props);
}

  componentDidMount() { 
    if( localStorage.getItem('location')!=='/policy'){
      localStorage.setItem('location', '/policy'); 
    }
  }

  render() {
    return (
<div className={classes.mainDiv} style={{transform: this.props.sideBar?'translateX(-34%)':'' }} onClick={this.props.outsideHandle}>
        <div className={classes.head}></div>
        <div className={classes.titleDiv}>
	  <p className={classes.mainTitle}>Bilgi Güvenliği Politikamız</p>
        </div>
        <div className={classes.policyDiv}>
          <p>Semper Teknoloji Araştırma Ve Geliştirme DAN. SAN. VE TİC. A.Ş. olarak bilgi güvenliği politikamız dahilinde; </p>
		<ul>
			<li>Bilgi varlıklarını yönetmeyi, varlıkların güvenlik değerlerini, ihtiyaçlarını ve risklerini belirlemeyi, güvenlik risklerine yönelik kontrolleri geliştirmeyi ve uygulamayı, </li>
			<li>Bilgi varlıkları, değerleri, güvenlik ihtiyaçları, zafiyetleri, varlıklara yönelik tehditlerin, tehditlerin sıklıklarının saptanması için yöntemlerin belirleyeceği çerçeveyi tanımlamayı, </li> 
			<li>Tehditlerin varlıklar üzerindeki gizlilik, bütünlük, erişilebilirlik etkilerini değerlendirmeye yönelik bir çerçeveyi tanımlamayı,</li> 
			<li>Risklerin işlenmesi için çalışma esaslarını ortaya koymayı, </li>
			<li>Hizmet verilen kapsam bağlamında teknolojik beklentileri gözden geçirerek riskleri sürekli takip etmeyi, </li>
			<li>Tabi olduğu ulusal veya uluslararası düzenlemelerden, yasal ve ilgili mevzuat gereklerini yerine getirmekten, anlaşmalardan doğan yükümlülüklerini karşılamaktan, iç ve dış paydaşlara yönelik kurumsal sorumluluklarından kaynaklanan bilgi güvenliği gereksinimlerini sağlamayı, </li>
			<li>Hizmet sürekliliğine yönelik bilgi güvenliği tehditlerinin etkisini azaltmayı ve sürekliliğe katkıda bulunmayı, </li>
			<li>Gerçekleşebilecek bilgi güvenliği olaylarına hızla müdahale edebilecek ve olayın etkisini minimize edecek yetkinliğe sahip olmayı, </li>
			<li>Maliyet etkin bir kontrol altyapısı ile bilgi güvenliği seviyesini zaman içinde korumayı ve iyileştirmeyi, </li>
			<li>Kurum itibarını geliştirmeyi, bilgi güvenliği temelli olumsuz etkilerden korumayı, </li>
			<li>Bilgi Güvenliği Yönetim Sisteminin sürekliliğini sağlamayı, </li>
			<li>Bilgi Güvenliği Yönetim Sistemini uluslararası standardına uygun olarak sürekli iyileştirmeyi, </li>
			<li>Gerekli bilgi güvenliği görev ve sorumluluklarını belirlemeyi, gerekli kaynağı sağlamayı ve ilgili atamaları yapmayı, </li>
			<li>Bilgi Güvenliği Yönetim Sisteminin işletilmesinde istisnai ve kapsam dışı faaliyetleri de göz önünde bulundurmayı, </li>
			<li>6698 sayılı Kişisel Verilerin Korunması Kanunu (KVKK) ve ilgili diğer mevzuat kapsamında çalışanlara, stajyerlere, ziyaretçilere, tedarikçilere ve ilgili diğer 3.taraflara ait kişisel bilgilerin işlenmesi, korunması ve imhası konusunda tüm sorumlulukları karşılamayı, </li>
		</ul>
	<p> taahhüt ederiz.</p>
	<b> Yönetim Kurulu Başkanı</b>
	<p> Prof. Dr. Şahin Albayrak</p>
        </div>
        <Footer  isTablet={this.props.isTablet} isMobile={this.props.isMobile} />
      </div>


    );
    }

}


export default Policy;
