import React, {Component} from 'react'

import classes from './Home.module.css';
import smart from '../../assets/images/smartIot.svg'
import bigData from '../../assets/images/bigData.svg'
import cyber from '../../assets/images/cyber.svg'
import future from '../../assets/images/future.svg'
import back from '../../assets/images/backgroundHeader.svg'
import Slider from "react-slick"; 

class Header extends Component  {
    constructor(props) {
        super(props);
        this.state={ 
        language:'eng'
      }   
    }
       
    MoreInfo(){
        localStorage.setItem('location', '/focus'); 
        window.location.href=`/focus `   
    }
    render() { 
        if(localStorage.getItem('language')!==this.state.language){
            this.setState({
                language:localStorage.getItem('language')
            })
        }
        const settings = {
            dots: true,
            infinite: true,
            swipeToSlide: true,
            speed: 3000,
            slidesToShow: 1,
            autoplay:true,
            autoplaySpeed:5000,
            slidesToScroll: 1,
            pauseOnFocus:true, 
        };
        return (
            <div className={classes.divSlide}>
            <Slider {...settings}> 
                <div  className={[classes.slide,classes.back1].join(' ')} style={{backgroundImage:"url("+back+"),linear-gradient(to bottom, #374366, #182343)"}}>
                    <img alt="img" className={classes.bigImg} src={smart}/>
                    <div className={classes.headerDiv}> 
                        <p className={classes.Headertitle}>{this.state.language==='eng'? 'Smart Buildings':'Akıllı Binalar\b \b\b\b'}</p>
                        <p style={{marginRight:this.props.isTablet? '221px':'19px'}} className={[classes.Headertitle, classes.bold].join(' ')}>& IoT</p>
                        <p style={{display:this.props.isTablet? 'none':'block'}} className={classes.Headertext}>{this.state.language==='eng'? 'Energy consumption can be monitored, smart grids can be constructed with IoT which results in a more secure and efficient energy consumption and decrease in customer expenses.':'Enerji tüketimi takip edilebilir, IoT cihazlar ile akıllı şebekeler oluşturulabilir. Böylece daha güvenli ve verimli enerji tüketimi sağlanabilir ve müşterinin masrafları düşürülebilir.'}</p>
                        <button onClick={this.MoreInfo} className={[classes.headerButton, this.props.isTablet? classes.tabletHeaderButton: ''].join(' ')}>{this.state.language==='eng'? 'More Info':'Daha Fazla Bilgi'} </button>
                    </div>
                </div>
                <div className={[classes.slide,classes.back2].join(' ')} >
                    <img alt="img" className={classes.bigImg2} src={bigData}/>
                    <div className={classes.headerDiv}> 
                        <p className={[classes.Headertitle, classes.bold, classes.bold2].join(' ')}>{this.state.language==='eng'? 'Big Data':'Büyük Veri'} </p> 
                        <p className={[classes.Headertitle, classes.light].join(' ')}>{this.state.language==='eng'? '& Data Analytics':' Veri Analizi'}</p>
                        <p style={{display:this.props.isTablet? 'none':'block'}} className={classes.Headertext}>{this.state.language==='eng'? 'Big data is the term that is used to refer to the large volume of data. In today\'s business life, the data produced on a daily,  weekly or any other timely basis can be structured or unstructured. ':'Büyük veri, büyük miktarda veriye atıfta bulunan terimdir. Günümüzün iş hayatında, günlük, haftalık veya farklı bir zaman araşığında üretilen veriler düzenli veya düzensiz olabilir. Global düzeyde üretilen ve işlenen verilerin hacmi inanılmaz derecede büyük ve gün geçtikçe büyümeye devam ediyor.'}</p>
                        <button onClick={this.MoreInfo} className={[classes.headerButton,classes.button2, this.props.isTablet? classes.tabletHeaderButton: ''].join(' ')}>{this.state.language==='eng'? 'More Info':'Daha Fazla Bilgi'}</button>
                    </div>
                </div>
                <div className={[classes.slide,classes.back3].join(' ')}>
                    <img alt="img" className={classes.bigImg3} src={cyber}/>
                    <div className={[classes.headerDiv,classes.headerDiv3].join(' ')}> 
                        <p className={[classes.Headertitle, classes.bold, classes.bold3].join(' ')}>{this.state.language==='eng'? 'Cyber':'Siber'} </p>
                        <p className={[classes.Headertitle, classes.bold3].join(' ')}>{this.state.language==='eng'? 'Security ':'Güvenlik'}</p>
                        <p style={{display:this.props.isTablet? 'none':'block'}} className={classes.Headertext}>{this.state.language==='eng'? 'The focus of our cyber security team is to address intelligent security solutions to meet the challenges with increased complexity of information and network systems which are the backbone of modern society. ':'Siber güvenlik ekibimizin odak noktası, modern toplumun belkemiğini oluşturan bilgi ve ağ sistemlerinin artan karmaşıklığı ile zorlukların üstesinden gelmek için akıllı güvenlik çözümlerini ele almaktır.'}</p>
                        <button onClick={this.MoreInfo} className={[classes.headerButton,classes.button3, this.props.isTablet? classes.tabletHeaderButton: ''].join(' ')}>{this.state.language==='eng'? 'More Info':'Daha Fazla Bilgi'}</button>
                    </div>
                </div>
                <div className={[classes.slide,classes.back4].join(' ')}  >
                    <img alt="img" className={[classes.bigImg3,classes.bigImg4].join(' ')} src={future}/>
                    <div className={classes.headerDiv}> 
                        <p className={[classes.Headertitle, classes.bold, classes.bold2].join(' ')}>{this.state.language==='eng'? 'Future ':'Gelecek Nesil'} </p>
                        <p className={[classes.Headertitle, classes.light].join(' ')}>{this.state.language==='eng'? 'Mobile Works ':'Mobil Ağlar'}</p>
                        <p style={{display:this.props.isTablet? 'none':'block'}} className={classes.Headertext}>{this.state.language==='eng'? 'The last decade has witnessed fast paced evolution of  telecommunication networks, which is strongly driven by their  positioning as main channel of delivery of most modern services of smart cities.' :'Son on yılda telekomünikasyon şebekelerinin hızla gelişen evrimine şahit olduk. Bunun sebebi de bu şebekelerin kendilerini en modern akıllı şehir uygulamalarının merkez kanalı olmak için çalışmaları. '} </p>
                        <button onClick={this.MoreInfo} className={[classes.headerButton,classes.button4, this.props.isTablet? classes.tabletHeaderButton: ''].join(' ')}>{this.state.language==='eng'? 'More Info':'Daha Fazla Bilgi'}</button>
                    </div>
                </div>  </Slider> 
            </div>     
        
        );
    }
}


export default Header;